import authService from '../../services/auth'

import { PROFILE_GENERAL_ADM } from '../../config/consts'

/* --------- Login --------- */
export const LOGIN_START = 'LOGIN_START'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'

const loginStart = () => ({
  type: LOGIN_START,
})

const loginError = () => ({
  type: LOGIN_ERROR,
})

export const loginSuccess = ({ profileId, token }) => ({
  type: LOGIN_SUCCESS,
  profileId,
  token
})

export const login = params => dispatch => {
  dispatch(loginStart())
  return new Promise((resolve, reject) =>
    authService
      .login(params)
      .then(response => {
        dispatch(loginSuccess(response.data.data))
        resolve(response.data.data)
      })
      .catch(e => {
        dispatch(loginError())
        reject(e)
      }),
  )
}

/* --------- Request New Password --------- */
export const REQUEST_NEW_PASSWORD_START = 'REQUEST_NEW_PASSWORD_START'
export const REQUEST_NEW_PASSWORD_ERROR = 'REQUEST_NEW_PASSWORD_ERROR'
export const REQUEST_NEW_PASSWORD_SUCCESS = 'REQUEST_NEW_PASSWORD_SUCCESS'

const requestNewPasswordStart = () => ({
  type: REQUEST_NEW_PASSWORD_START,
})

const requestNewPasswordError = () => ({
  type: REQUEST_NEW_PASSWORD_ERROR,
})

const requestNewPasswordSuccess = () => ({
  type: REQUEST_NEW_PASSWORD_SUCCESS,
})

export const requestNewPassword = email => dispatch => {
  dispatch(requestNewPasswordStart())
  return new Promise((resolve, reject) =>
    authService
      .requestNewPassword(email)
      .then(() => {
        dispatch(requestNewPasswordSuccess())
        resolve()
      })
      .catch(e => {
        dispatch(requestNewPasswordError())
        reject(e)
      }),
  )
}

/* --------- Change Password --------- */
export const CHANGE_PASSWORD_START = 'CHANGE_PASSWORD_START'
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR'
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS'

const changePasswordStart = () => ({
  type: CHANGE_PASSWORD_START,
})

const changePasswordError = () => ({
  type: CHANGE_PASSWORD_ERROR,
})

const changePasswordSuccess = () => ({
  type: CHANGE_PASSWORD_SUCCESS,
})

export const changePassword = password => dispatch => {
  dispatch(changePasswordStart())
  return new Promise((resolve, reject) =>
    authService
      .changePassword(password)
      .then(() => {
        dispatch(changePasswordSuccess())
        resolve()
      })
      .catch(e => {
        dispatch(changePasswordError())
        reject(e)
      }),
  )
}

/* --------- Get Profile --------- */
export const GET_PROFILE_START = 'GET_PROFILE_START'
export const GET_PROFILE_ERROR = 'GET_PROFILE_ERROR'
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS'

const getProfileStart = () => ({
  type: GET_PROFILE_START,
})

const getProfileError = () => ({
  type: GET_PROFILE_ERROR,
})

const getProfileSuccess = profile => ({
  type: GET_PROFILE_SUCCESS,
  profile,
})

export const getProfile = () => dispatch => {
  dispatch(getProfileStart())
  return new Promise((resolve, reject) =>
    authService
      .getProfile()
      .then(response => {
        const profile = response.data.data
        const initials = authService.getInitials(profile)
        dispatch(getProfileSuccess({ ...profile, initials }))
        resolve({ ...profile, initials })
      })
      .catch(e => {
        dispatch(getProfileError())
        reject(e)
      }),
  )
}

/* --------- Logout --------- */
export const LOGOUT_START = 'LOGOUT_START'
export const LOGOUT_ERROR = 'LOGOUT_ERROR'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'

const logoutStart = () => ({
  type: LOGOUT_START,
})

const logoutError = () => ({
  type: LOGOUT_ERROR,
})

const logoutSuccess = () => ({
  type: LOGOUT_SUCCESS,
})

export const logout = () => dispatch => {
  dispatch(logoutStart())
  return authService
    .logout()
    .then(response => {
      dispatch(logoutSuccess(response.data.data))
    })
    .catch(e => {
      dispatch(logoutError())
      return e
    })
}

export const CHANGE_GENERAL_ADM_PROFILE = 'CHANGE_GENERAL_ADM_PROFILE'

export const changeGeneralAdmToAnotherProfile = ({
  profileId = PROFILE_GENERAL_ADM,
  profileChangeByGeneralAdm = false,
  userId = 0,
}) => ({
  type: CHANGE_GENERAL_ADM_PROFILE,
  profileId,
  profileChangeByGeneralAdm,
  userId,
})

/* --------- Generate new access token --------- */
export const SET_NEW_ACCESS_TOKEN_START = 'SET_NEW_ACCESS_TOKEN_START'
export const SET_NEW_ACCESS_TOKEN_SUCCESS = 'SET_NEW_ACCESS_TOKEN_SUCCESS'
export const SET_NEW_ACCESS_TOKEN_ERROR = 'SET_NEW_ACCESS_TOKEN_ERROR'

export const setNewAccessTokenSuccess = token => ({
  type: SET_NEW_ACCESS_TOKEN_SUCCESS,
  token
})
export const setNewAccessTokenStart = () => ({
  type: SET_NEW_ACCESS_TOKEN_START
})
export const setNewAccessTokenError = () => ({
  type: SET_NEW_ACCESS_TOKEN_ERROR
})
