import React from 'react'
import Helmet from 'react-helmet'
import { Page } from 'framework7-react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { isDesktopDevice } from 'utils/layout'
import { getNetworkStatus } from 'store/actions/network'
import {
  addApplicationDate,
  saveApplicationDates,
  updateSelectedOrderApplicationDate,
} from 'store/actions/order'
import {
  CartLayout,
  Form,
  DefaultHeader,
  PrimaryButton,
  PrimaryInput,
  SecondaryButton,
} from 'components'
import { APPLICATION_TYPES, MONTHS, DAY_NAMES } from 'config/consts'
import { ORDER_CANCEL_ROUTE } from 'routes/consts'
import { CHEMICAL_ORDER_STATUS } from 'components/OrderProductList/helpers'
import FormLine from './FormLine/FormLine'
import './OrderApplication.scss'
import { CANCEL_REQUEST_STATUS } from './helper'

class OrderApplication extends React.Component {
  constructor(props) {
    super(props)

    let { applicationDate } = props.selectedProduct

    const setApplicationDate = props.applicationDates.find(
      a => a.orderPivotChemicalId === props.selectedProduct.id,
    )
    if (setApplicationDate && !applicationDate) {
      applicationDate = setApplicationDate.date
    }

    this.canEdit = props.selectedProduct.chemicalId // Não é um pedido offline

    let { value: applicationTypeValue } = props.selectedProduct.applicationType

    if (applicationTypeValue === null || applicationTypeValue === undefined)
      applicationTypeValue = props.selectedProduct.applicationType

    this.applicationType = Object.values(APPLICATION_TYPES).find(
      a => a.value === +applicationTypeValue,
    ).label

    this.state = {
      date: applicationDate,
      isLoading: false,
      hasError: false,
    }
  }

  async handleSubmit() {
    this.setState({ isLoading: true })
    const { selectedProduct } = this.props
    const { date } = this.state
    const applicationDate = {
      orderPivotChemicalId: selectedProduct.id,
      date,
    }
    const isOnline = await this.props.getNetworkStatus()

    if (isOnline) {
      try {
        await this.props.saveApplicationDates([applicationDate])
        this.setState({ hasError: false })
      } catch {
        this.setState({ hasError: true })
      }
    } else {
      this.props.addApplicationDate(applicationDate)
    }

    this.setState({ isLoading: false })

    if (!this.state.hasError) {
      this.props.updateSelectedOrderApplicationDate(applicationDate)
      this.$f7router.back()
    }
  }

  isValidForm() {
    const { date } = this.state
    return this.canEdit && Boolean(date)
  }

  render() {
    const { selectedProduct, selectedPivot, chemicalResale } = this.props
    const { isLoading, hasError } = this.state
    const pageTitle = 'Corteva Workflow: Aplicar Produto'

    const statusEnabledToCancelRequest = [
      CHEMICAL_ORDER_STATUS.received,
      CHEMICAL_ORDER_STATUS.accepted,
    ]

    const hasCancelRequestPending =
      selectedProduct?.cancelRequest?.status === CANCEL_REQUEST_STATUS.pending

    const cancelRequestIsRejected =
      selectedProduct?.cancelRequest?.status === CANCEL_REQUEST_STATUS.rejected

    const enabledCancelRequest =
      !selectedProduct?.cancelRequest?.id &&
      statusEnabledToCancelRequest.includes(selectedProduct.chemicalOrderStatus)

    return (
      <Page className="order-application-page">
        <Helmet title={pageTitle} />
        <CartLayout>
          <DefaultHeader
            router={this.$f7router}
            headerTitle={
              <div className="order-application-page__header">
                <h1 className="order-application-page__header__title">{selectedProduct.name}</h1>
                <p className="order-application-page__header__days">{`Previsão de entrega: ${chemicalResale.resale.weekDaysToDeliver}`}</p>
              </div>
            }
            beforeTitle={selectedPivot.name}
            isModal={isDesktopDevice()}
          />
          <div className="order-application-page__body">
            <Form>
              <FormLine
                name="Dosagem"
                description={`${selectedProduct.dosage.toFixed(3)} ${chemicalResale.unitMeasure}`}
                splitIntoColumns
              />
              <FormLine
                name="Volume"
                description={`${selectedProduct.volume.toFixed(3) ||
                  selectedProduct.quantity.toFixed(2)} ${chemicalResale.unitMeasure}`}
                splitIntoColumns
              />
              <FormLine name="Forma de aplicação" description={this.applicationType} />
              <FormLine name="Revenda" description={chemicalResale.resale.nickname} />
              <FormLine
                name="Data de aplicação"
                description="Selecione a data que será realizada a aplicação do químico no pivô."
              >
                <PrimaryInput
                  className="order-application-page__date"
                  required
                  disabled={!this.canEdit}
                  placeholder="dd/mm/aaaa"
                  calendarParams={{
                    dateFormat: 'dd/mm/yyyy',
                    monthNames: MONTHS,
                    dayNamesShort: DAY_NAMES,
                    value: this.state.date && [this.state.date],
                    on: {
                      change: e => this.setState({ date: e.value[0] }),
                    },
                    closeOnSelect: true,
                  }}
                  type="datepicker"
                />
              </FormLine>
              {hasError && (
                <div className="order-application-page__error-message">
                  <p>Ocorreu um erro ao tentar salvar a data de aplicação</p>
                </div>
              )}

              {hasCancelRequestPending && (
                <div className="order-application-page__warning-message">
                  <p>Esse químico tem uma solicitação de cancelamento pendente!</p>
                </div>
              )}

              {cancelRequestIsRejected && (
                <div className="order-application-page__error-message">
                  <p>A solicitação de cancelamento desse químico foi rejeitada pela revenda!</p>
                </div>
              )}

              {enabledCancelRequest && (
                <SecondaryButton
                  isLoading={isLoading}
                  onClick={() => this.$f7router.navigate({ name: ORDER_CANCEL_ROUTE })}
                >
                  Solicitar cancelamento
                </SecondaryButton>
              )}
              <PrimaryButton
                disabled={!this.isValidForm()}
                onClick={() => this.handleSubmit()}
                isLoading={isLoading}
              >
                Salvar
              </PrimaryButton>
            </Form>
          </div>
        </CartLayout>
      </Page>
    )
  }
}

const mapStateToProps = state => ({
  selectedOrder: state.order.selected,
  selectedPivot: state.order.selectedPivot,
  selectedProduct: state.order.selectedProduct,
  selectedFarm: state.farm.selected,
  applicationDates: state.order.applicationDates,
  chemicalResale:
    state.product.chemicalResales[
      state.order.selectedProduct.chemicalId || state.order.selectedProduct.id
    ],
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addApplicationDate,
      saveApplicationDates,
      getNetworkStatus,
      updateSelectedOrderApplicationDate,
    },
    dispatch,
  )

export default connect(mapStateToProps, mapDispatchToProps)(OrderApplication)
