import React from 'react'

import arrowIcon from '../../assets/vectors/chevron-down.svg'

import './Select.scss'

class Select extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showOptions: false,
    }

    this.setWrapperRef = this.setWrapperRef.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside)
  }

  setWrapperRef(node) {
    this.wrapperRef = node
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target))
      this.setState({ showOptions: false })
  }

  render() {
    const { options, onChange, value } = this.props
    const { showOptions } = this.state

    return (
      <div className="select__container" ref={this.setWrapperRef}>
        {/* eslint-disable-next-line */}
        <div
          className={`select ${showOptions ? 'select--open' : ''}`}
          onClick={() => this.setState(oldState => ({ showOptions: !oldState.showOptions }))}
        >
          {(value && value.label) || 'Selecione'}
          <img className="select__arrow" src={arrowIcon} alt={showOptions ? 'Fechar' : 'Abrir'} />
        </div>
        {showOptions && (
          <div className="select__option">
            {options.map(p => (
              /* eslint-disable-next-line */
              <p
                key={p.value}
                className={`select__options ${
                  value && p.value === value.value ? 'select__options--selected' : ''
                }`}
                onClick={() => {
                  if (onChange) onChange(p)
                  this.setState({ showOptions: false })
                }}
              >
                {p.label}
              </p>
            ))}
          </div>
        )}
      </div>
    )
  }
}

export default Select
