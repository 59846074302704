/* --------- Select --------- */
export const SET_SELECTED_PIVOT = 'SET_SELECTED_PIVOT'

const selectedPivot = pivot => ({
  type: SET_SELECTED_PIVOT,
  pivot,
})

export const setSelectedPivot = pivot => dispatch =>
  new Promise(resolve => resolve(dispatch(selectedPivot(pivot))))
