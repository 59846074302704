import { Capacitor } from '@capacitor/core'
import config from 'config'

export const isIosDevice = () => Capacitor.getPlatform() === 'ios'

export const getReportUrl = (harvestId, reportType, token, id) =>{
  if(isIosDevice) {
    return reportType === 'reentryReport' ?
    `${config.baseUrl}/orders/reentry-report?harvestId=${harvestId}&token=${token}&nucleuId=${id}`
      :
    `${config.baseUrl}/unities/report/${id}?harvestId=${harvestId}&token=${token}`
  }
  
  return reportType === 'reentryReport' ?
  `${config.baseUrl}/orders/reentry-report?harvestId=${harvestId}&nucleuId=${id}`
    :
  `${config.baseUrl}/unities/report/${id}?harvestId=${harvestId}`
} 