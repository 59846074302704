import React from 'react'
import PropTypes from 'prop-types'
import OrderTableItem from './OrderTableItem/OrderTableItem'
import './OrderTable.scss'

const OrderTable = ({headers, data, lineClick, orderPageType}) => (
  <ul className='order-table'>
    <li className='order-table__list-header--only-desktop'>
      {headers.map(header => (
        <p className={`${orderPageType === 'ORDER' ? 'header-order' : 'header-normal' }`} key={header.id}>{header.name}</p>
      ))}
    </li>
    {data.map((item) => (
      <OrderTableItem key={`${item.id}`} item={item} lineClick={lineClick} orderPageType={orderPageType} />
    ))}
  </ul>
)

OrderTable.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  lineClick: PropTypes.func.isRequired
}
  

export default OrderTable