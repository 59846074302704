import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'framework7-react'

import { HarvestSelector } from 'components'
import rootPages from 'config/rootPages'
import { ReactComponent as LogoHeaderDesktop } from 'assets/vectors/logo-horizontal.svg'
import { setHomeTab } from 'store/actions/ui'
import Breadcrumb from '../Breadcrumb/Breadcrumb'
import CartResume from '../CartResume/CartResume'
import { getProfile } from '../../store/actions/auth'

import './Layout.scss'
import { isDesktopDevice } from '../../utils/layout'
import UserProfile from './components/UserProfile'

const Layout = ({ children, breadcrumbItems, showCart }) => {
  const selectedTab = useSelector(({ ui }) => ui.tab)
  const { profile } = useSelector(state => state.auth)
  const dispatch = useDispatch()

  React.useEffect(() => {
    if (isDesktopDevice() && !profile?.name) {
      dispatch(getProfile())
    }
  }, [dispatch, profile])

  const renderNavLinks = React.useMemo(
    () =>
      rootPages.map(tab => (
        <Link
          key={tab.path}
          tabLink={`#${tab.path}`}
          tabLinkActive={selectedTab === tab.id}
          onClick={() => {
            dispatch(setHomeTab(tab.id))
            if (window.location.pathname !== '/home') {
              window.location.replace('/home')
            }
          }}
        >
          <img src={tab.icon} alt={tab.label} />
          <span>{tab.label}</span>
        </Link>
      )),
    [dispatch, selectedTab],
  )

  return (
    <section className="tabs--desktop-helper">
      <header className="home__desktop-header">
        <div className="desktop-header__top">
          <div className="desktop-header__links">
            <Link href="/">
              <LogoHeaderDesktop />
            </Link>
            <nav>{renderNavLinks}</nav>
          </div>
          <UserProfile />
        </div>
        <div className="desktop-header__bottom">
          <div className="desktop-header__harvest">
            <HarvestSelector />
            {breadcrumbItems ? (
              <>
                | <Breadcrumb items={breadcrumbItems} />
              </>
            ) : null}
          </div>
          {showCart ? <CartResume /> : null}
        </div>
      </header>
      {children}
    </section>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  breadcrumbItems: PropTypes.arrayOf(
    PropTypes.object
  ),
  showCart: PropTypes.bool,
}

Layout.defaultProps = {
  breadcrumbItems: null,
  showCart: false,
}

export default Layout
