import React from 'react'
import { connect } from 'react-redux'
import { F7App, View } from 'framework7-react'
import { hotjar } from 'react-hotjar'

import routes from './routes'

import { watchKeyboardVisibility } from './store/actions/virtualKeyboard'

const f7params = {
  name: 'Corteva Workflow',
  id: 'com.corteva.workflow',
  touch: { fastClicks: true },
  routes,
}

if (process.env.REACT_APP_HOTJAR_ID) {
  hotjar.initialize(process.env.REACT_APP_HOTJAR_ID, 6)
}

class MobileMain extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this.$f7ready(() => {
      if (window.location.pathname === '/') window.location.pathname = '/home'
    })
    this.props.watchVirtualKeyboard()
  }

  render() {
    const { isKeyboardUp } = this.props

    return (
      <F7App
        params={f7params}
        colorTheme="blue"
        className={`${isKeyboardUp ? 'device-keyboard-visible' : ''}`}
      >
        <View
          main
          iosSwipeBack={false}
          auroraSwipeBack={false}
          mdSwipeBack={false}
          url="/"
          pushState
          pushStateRoot={window.location.origin}
          pushStateSeparator=""
        />
      </F7App>
    )
  }
}

const mapStateToProps = state => ({
  isKeyboardUp: state.keyboard.isVisible,
})

const mapDispatchToProps = dispatch => ({
  watchVirtualKeyboard: () => dispatch(watchKeyboardVisibility()),
})

export default connect(mapStateToProps, mapDispatchToProps)(MobileMain)
