import React from 'react'
import { useDispatch } from 'react-redux'

import { ReactComponent as OptionsIcon } from '../../../assets/vectors/cart-option-icon.svg'
import dangerIcon from '../../../assets/vectors/danger-icon.svg'

import { PRODUCT_PIVOTS_ROUTE, CONFIG_PRODUCT_ROUTE } from '../../../routes/consts'

import { setSelectedProduct, setSelectedPivot, toggleCartOpen } from '../../../store/actions/cart'

import './ProductList.scss'

const ProductList = ({ products, router }) => {
  const dispatch = useDispatch()

  return (
    <ul className="cart-product-list__container">
      {products.map(c => {
        const pivots = c.pivots && Object.values(c.pivots)
        return (
          <li key={c.name} className="cart-product-list__item">
            <button
              className="cart-product-list__item__option"
              aria-label="Alterar Produto"
              title="Alterar Produto"
              onClick={() => {
                dispatch(setSelectedProduct(c))
                dispatch(toggleCartOpen())
                if (pivots.length > 1) router.navigate({ name: PRODUCT_PIVOTS_ROUTE })
                else {
                  dispatch(setSelectedPivot(pivots[0]))
                  router.navigate({ name: CONFIG_PRODUCT_ROUTE })
                }
              }}
            >
              <OptionsIcon />
            </button>
            <div className="cart-product-list__item__name">
              <h1 className="cart-product-list__item__product">{c.name}</h1>
              <h1 className="cart-product-list__item__quantity">{`${c.quantity} ${c.unitMeasure}`}</h1>
            </div>
            <div className="cart-product-list__item__pivot">
              {pivots.map(p => (
                <p key={p.name} className="cart-product-list__item__pivots">
                  {`${p.name} - Dosagem ${p.dosage} ${c.unitMeasure} - ${p.quantity} ${c.unitMeasure}`}
                </p>
              ))}
              {c.usedStock && (
                <p className="cart-product-list__item__stock">
                  {`Estoque - ${c.usedStock} ${c.unitMeasure}`}
                </p>
              )}
            </div>
            {!!c.stock && !c.usedStock && !c.ignoreStock && (
              <img
                className="cart-product-list__item__alert"
                src={dangerIcon}
                alt="Alerta de Sobra"
              />
            )}
          </li>
        )
      })}
    </ul>
  )
}

export default ProductList
