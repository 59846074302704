import React from 'react'
import PropTypes from 'prop-types'
import { isDesktopDevice } from 'utils/layout'
import dangerIcon from 'assets/vectors/danger-icon.svg'
import { getOrderStatusMessage, isMissingApplicationDate, getOrderNumberMessage, getTechnicianMessage, anyChemicalOrderWasCanceled } from '../helpers'
import './PageTypeOrder.scss'

const PageTypeOrder = ({ item }) => {
  const { id, technician, resaleOrders } = item

  return(
    <div className='page-type-order'>
      <h1 className="page-type-order--name">{getOrderNumberMessage(id)}</h1>
      <p className={`page-type-order--value ${isDesktopDevice()? '' : 'status'}`}>
        {getOrderStatusMessage(id)}
      </p>
      <p className="page-type-order--value">
        {getTechnicianMessage(technician)}
      </p>
      {isMissingApplicationDate(item)? 
        <p className="page-type-order--aplication-date">Falta indicar data de aplicação</p>
        :
        null
      }
      {anyChemicalOrderWasCanceled(resaleOrders) ? (
        <img src={dangerIcon} alt="Aviso" className="page-type-order--cancel-icon" />
        ) :
        null}
    </div>
  )
}

PageTypeOrder.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    date: PropTypes.string.isRequired,
    chemicals: PropTypes.arrayOf(PropTypes.object),
    resaleOrders: PropTypes.arrayOf(PropTypes.object),
    technician: PropTypes.string.isRequired
  }).isRequired,
}

export default PageTypeOrder