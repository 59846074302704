import React from 'react'
import PropTypes from 'prop-types'
import arrow from 'assets/vectors/arrow.svg'
import { dateToBr } from 'services/utils'
import { status } from './helpers'
import './OrderProductList.scss'

const OrderProductList = ({ chemicals, chemicalLineClick }) => (
  <ul className="order-product-list__container">
    {
      chemicals.map(chemical => {
        const { name, chemicalOrderStatus, applicationDate, orderDate } = chemical
        return (
          /* eslint-disable-next-line */
          <li
            key={chemical.name}
            className="order-product-list__item"
            onClick={() => chemicalLineClick({ ...chemical, name })}>
            <div className="order-product-list__item__text-container">
              <h1 className="order-product-list__item__name">{name}</h1>
              <p
                className='order-product-list__item__date--request'
              >
                {`Data do pedido: ${orderDate}`}
              </p>
              <p
                className={`order-product-list__item__date ${applicationDate &&
                  'order-product-list__item__date--with'}`}
              >
                {applicationDate
                  ? `Produto aplicado: ${dateToBr(applicationDate)}`
                  : 'Falta indicar data da aplicação'}
              </p>
              <p className="order-product-list__item__status">
                {chemicalOrderStatus ? `Pedido ${status[+chemicalOrderStatus]}` : 'Pedido Pendente'}
              </p>
            </div>
            <img className="order-product-list__item__arrow" src={arrow} alt="Seta" />
          </li>
        )
      })}
  </ul>
)

OrderProductList.propTypes = {
  chemicals: PropTypes.arrayOf(PropTypes.shape({
    applicationDate: PropTypes.string,
    applicationType: PropTypes.string.isRequired,
    chemicalId: PropTypes.number.isRequired,
    chemicalOrderStatus: PropTypes.string,
    dosage: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    orderDate: PropTypes.string.isRequired,
    pivot: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      area: PropTypes.number.isRequired,
      harvestId: PropTypes.number.isRequired,
    }),
    volume: PropTypes.number.isRequired,
  })).isRequired,
  chemicalLineClick: PropTypes.func.isRequired
}

export default OrderProductList