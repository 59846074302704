import React from 'react'
import { connect } from 'react-redux'

import PrimaryButton from '../../PrimaryButton/PrimaryButton'
import Modal from '../../Modal/Modal'

import { APPLICATION_TYPES } from '../../../config/consts'

import './ResaleStockModal.scss'

class ResaleStockModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    const { open, close, selectedProduct, chemicalResale } = this.props

    return selectedProduct && selectedProduct.requestedVolume ? (
      <Modal open={open} onCloseModal={() => close()}>
        <h1 className="modal__title">Estoque insuficiente</h1>
        <p className="modal__description">
          A quantidade que você está solicitando é maior que a disponível na revenda para o químico
          <span className="modal__description__name">{` ${selectedProduct.name}`}</span>.
        </p>
        <div className="resale-stock-modal__container">
          <p className="resale-stock-modal__quantity">Quantidade solicitada</p>
          <h3>{`${selectedProduct.requestedVolume.requestedVolume} ${chemicalResale.unitMeasure}`}</h3>
        </div>
        <div className="resale-stock-modal__stocks">
          {Object.keys(selectedProduct.requestedVolume.stocks).map(applicationId => (
            <div key={applicationId} className="resale-stock-modal__stocks__item">
              <p className="resale-stock-modal__stocks__item__p">
                {APPLICATION_TYPES.find(a => `${a.value}` === `${applicationId}`).label}
              </p>
              <h5>
                {selectedProduct.requestedVolume.stocks[applicationId].reduce(
                  (a, s) =>
                    `${a ? `${a}, ` : ''}${s.quantity}x ${s.volume} ${chemicalResale.unitMeasure}`,
                  '',
                )}
              </h5>
            </div>
          ))}
        </div>
        <div className="resale-stock-modal__container">
          <p className="resale-stock-modal__quantity">Quantidade em estoque</p>
          <h3>{`${chemicalResale.stocks} ${chemicalResale.unitMeasure}`}</h3>
        </div>
        <PrimaryButton onClick={() => close()}>Entendi</PrimaryButton>
      </Modal>
    ) : null
  }
}

const mapStateToProps = state => ({
  selectedProduct: state.cart.selected,
  chemicalResale: state.cart.selected
    ? state.product.chemicalResales[state.cart.selected.id]
    : null,
})

export default connect(mapStateToProps)(ResaleStockModal)
