import React from 'react'

import Navbar from '../Navbar/Navbar'

import './DefaultHeader.scss'

export default ({ title, headerTitle, beforeTitle, router, isModal }) => (
  <div className="default-header">
    <Navbar router={router} backButton blue center={beforeTitle} isModal={isModal} />
    {headerTitle || <h1 className="default-header__title">{title}</h1>}
  </div>
)
