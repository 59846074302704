import React from 'react'

import Header from '../../Header/Header'

import cartIcon from '../../../assets/vectors/cart-icon.svg'

import './EmptyState.scss'

export default () => (
  <div className="cart-empty">
    <img className="cart-empty__illustration" src={cartIcon} alt="Carrinho Vazio" />
    <Header title="Carrinho vazio" content="Adicione produtos para realizar seu pedido." />
  </div>
)
