import { filterByTerm, sortInAlphabeticalOrder } from 'utils/sortFunctions'
import { checkChemicalApplicationDate, checkChemicalCanceled } from '../helpers'

export const orderPivotsHeaders =[{ id: 0, name:'PIVÔS' }]

export const createBreadcrumbItems = (nucleusName, farmName) => 
  [
    {
      name: nucleusName,
      route: '/home',
    },
    {
      name: farmName,
    },
  ]

export const filterPivots = (pivots, searchText) => {
  const newPivots = pivots.map((pivot) => 
    ({
      ...pivot,
      isAnyChemicalCanceled: checkChemicalCanceled([pivot]), 
      isMissingApplicationDate: checkChemicalApplicationDate([pivot])
    })
  )

  const pivotsWithoutApplicationDate = newPivots.filter(newPivot => newPivot.isMissingApplicationDate)
  const pivotsWithApplicationDate = newPivots.filter(newPivot => !newPivot.isMissingApplicationDate)

  const pivotsData = [...sortInAlphabeticalOrder(pivotsWithoutApplicationDate), ...sortInAlphabeticalOrder(pivotsWithApplicationDate)]
  
  if(searchText === '') return pivotsData

  return filterByTerm(pivotsData, searchText) 
  
}