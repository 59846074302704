import React from 'react'
import PropTypes from 'prop-types'
import PrimaryInput from '../PrimaryInput/PrimaryInput'
import './LayoutOrderPage.scss'

const LayoutOrderPage = ({ tableTitle, placeholder, setSearchText, searchText, children, isFilterButton, filterModalOnClick }) => (
  <div className='layout-order-page'>
    <div className="layout-order-page__header">
      <div className="layout-order-page__searchbar">
        <PrimaryInput
          placeholder={placeholder}
          onChange={e => setSearchText(e.target.value)}
          value={searchText}
          hideLabel
        />
      </div>
      {isFilterButton? 
        <button className="layout-order-page__filter" onClick={() => filterModalOnClick()}>
          Filtros
        </button>
        :
        null
      }
    </div>
    <div className="layout-order-page__content">
      <h1 className='layout-order-page__content--title'>{tableTitle || null}</h1>
      {children}
    </div>
  </div>
)

LayoutOrderPage.prototypes = {
  placeholder: PropTypes.string.isRequired, 
  setSearchText: PropTypes.func.isRequired, 
  searchText: PropTypes.string.isRequired,
  tableTitle: PropTypes.string,
  isFilterButton: PropTypes.bool,
  filterModalOnClick: PropTypes.func,
  children: PropTypes.node.isRequired
}

LayoutOrderPage.defaultProps = {
  tableTitle: undefined,
  isFilterButton: false,
  filterModalOnClick: () => {},
}

export default LayoutOrderPage