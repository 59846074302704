import React from 'react'
import { connect, useDispatch } from 'react-redux'

import PrimaryInput from '../PrimaryInput/PrimaryInput'
import EmptyScreen from '../EmptyScreen/EmptyScreen'

import storageIcon from '../../assets/vectors/storage.svg'

import { CONFIG_PRODUCT_ROUTE } from '../../routes/consts'

import { setSelectedProduct } from '../../store/actions/product'
import { removeFromCart, clearSelected } from '../../store/actions/cart'

import './ProductList.scss'
import ProductListItem from '../ProductListItem/ProductListItem'

function ProductList(props) {
  const { cart, products, router, pivot, chemicalResales } = props
  const [searchText, setSearchText] = React.useState('')

  const dispatch = useDispatch()

  function isInCart(product) {
    const existingProduct = cart[product.id]
    if (!existingProduct) return false
    const existingPivot = existingProduct.pivots[pivot.id]
    return !!existingPivot
  }

  function handleSearchTerm(e) {
    setSearchText(e.target.value)
  }

  function withSearchText(product) {
    if (!searchText) {
      return true
    }
    const searchTerm = new RegExp(searchText, 'i')
    return product.name.match(searchTerm)
  }

  function handleOnClick(p) {
    const isProductInCart = isInCart(p)
    dispatch(setSelectedProduct(p))
    if (isProductInCart) {
      dispatch(removeFromCart(p.id, pivot.id))
    } else {
      dispatch(clearSelected())
      router.navigate({ name: CONFIG_PRODUCT_ROUTE })
    }
  }

  if (!products || !products.length) {
    return (
      <EmptyScreen
        icon={storageIcon}
        title="Nenhum produto encontrado"
        description="Não existe nenhum produto criado até o momento."
      />
    )
  }

  return (
    <>
      <div className="product-list__searchbar">
        <PrimaryInput
          placeholder="Busque pelo nome do químico"
          onChange={handleSearchTerm}
          value={searchText}
          hideLabel
        />
      </div>
      <div className="product-list__header desktop-only">
        <p>Nome do produto</p>
      </div>
      <ul className="product-list__container">
        {products.filter(withSearchText).map(product => {
          const chemicalResale = chemicalResales[product.id]
          return chemicalResale ? (
            <ProductListItem
              key={product.name}
              name={product.name}
              handleOnClick={() => handleOnClick(product)}
              stocks={chemicalResale.stocks}
              unitMeasure={chemicalResale.unitMeasure}
              isProductInCart={isInCart(product)}
            />
          ) : null
        })}
      </ul>
    </>
  )
}

const mapStateToProps = state => ({
  products: state.product.products,
  cart: state.cart.cart,
  pivot: state.pivot.selected,
  chemicalResales: state.product.chemicalResales,
})

const mapDispatchToProps = dispatch => ({
  setSelectedProduct: product => dispatch(setSelectedProduct(product)),
  removeFromCart: (productId, pivotId) => dispatch(removeFromCart(productId, pivotId)),
  clearSelected: () => dispatch(clearSelected()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProductList)
