import orderService from '../../services/order'

/* --------- Offline --------- */
export const ADD_OFFLINE_ORDER = 'ADD_OFFLINE_ORDER'
export const SET_OFFLINE_ORDERS = 'SET_OFFLINE_ORDERS'
export const SET_ALL_OFFLINE_ORDERS = 'SET_ALL_OFFLINE_ORDERS'

const addOfflineOrderSuccess = offlineOrder => ({
  type: ADD_OFFLINE_ORDER,
  offlineOrder,
})

export const addOfflineOrder = offlineOrder => dispatch =>
  new Promise(resolve => resolve(dispatch(addOfflineOrderSuccess(offlineOrder))))

const setOfflineOrdersSuccess = offlineOrders => ({
  type: SET_OFFLINE_ORDERS,
  offlineOrders,
})

const setAllOfflineOrders = allOfflineOrders => ({
  type: SET_ALL_OFFLINE_ORDERS,
  allOfflineOrders,
})

export const setOfflineOrders = (farms, allOfflineOrders) => dispatch =>
  new Promise(resolve => {
    const offlineOrders = orderService.getOrderFromFarms(farms, allOfflineOrders)
    resolve(dispatch(setOfflineOrdersSuccess(offlineOrders)))
  })

/* --------- Orders --------- */

export const SET_ORDERS = 'SET_ORDERS'
export const FETCH_ORDERS_START = 'FETCH_ORDERS_START'
export const FETCH_ORDERS_ERROR = 'FETCH_ORDERS_ERROR'
export const FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS'

const setOrdersSuccess = orders => ({
  type: SET_ORDERS,
  orders,
})

const fetchOrdersStart = () => ({
  type: FETCH_ORDERS_START,
})

const fetchOrdersError = () => ({
  type: FETCH_ORDERS_ERROR,
})

const fetchOrdersSuccess = allOrders => ({
  type: FETCH_ORDERS_SUCCESS,
  allOrders,
})

export const setOrders = (farms, allOrders) => dispatch =>
  new Promise(resolve => {
    const orders = orderService.getOrderFromFarms(farms, allOrders)
    resolve(dispatch(setOrdersSuccess(orders)))
  })

export const fetchOrdersByHarvestId = (harvestId, query) => (dispatch, getState) => {
  dispatch(fetchOrdersStart())
  return new Promise((resolve, reject) =>
    orderService
      .getOrders(harvestId, query)
      .then(response => {
        const { order, farm } = getState()
        const { data } = response.data
        dispatch(fetchOrdersSuccess(data))
        setOrders(farm.farms, data)(dispatch)
        const filteredOfflineOrders = orderService.filterOfflineOrders(data, order.allOfflineOrders)
        dispatch(setAllOfflineOrders(filteredOfflineOrders))

        resolve(data)
      })
      .catch(e => {
        dispatch(fetchOrdersError())
        reject(e)
      }),
  )
}

/* --------- Save --------- */
export const SAVE_ORDERS_START = 'SAVE_ORDERS_START'
export const SAVE_ORDERS_ERROR = 'SAVE_ORDERS_ERROR'
export const SAVE_ORDERS_SUCCESS = 'SAVE_ORDERS_SUCCESS'

const saveOrdersStart = () => ({
  type: SAVE_ORDERS_START,
})

const saveOrdersError = () => ({
  type: SAVE_ORDERS_ERROR,
})

const saveOrdersSuccess = () => ({
  type: SAVE_ORDERS_SUCCESS,
})

export const saveOrders = offlineOrders => dispatch => {
  dispatch(saveOrdersStart())
  return new Promise(resolve =>
    orderService
      .saveOrders(offlineOrders)
      .then(response => {
        const { data } = response.data
        dispatch(saveOrdersSuccess(data))
        dispatch(setAllOfflineOrders([]))
        resolve(data)
      })
      .catch(e => {
        dispatch(saveOrdersError())
        resolve(e)
      }),
  )
}

/* --------- Application Date --------- */
export const ADD_APPLICATION_DATE = 'ADD_APPLICATION_DATE'
export const SAVE_APPLICATION_DATE_START = 'SAVE_APPLICATION_DATE_START'
export const SAVE_APPLICATION_DATE_ERROR = 'SAVE_APPLICATION_DATE_ERROR'
export const SAVE_APPLICATION_DATE_SUCCESS = 'SAVE_APPLICATION_DATE_SUCCESS'

const addApplicationDateSuccess = applicationDate => ({
  type: ADD_APPLICATION_DATE,
  applicationDate,
})

export const addApplicationDate = applicationDate => dispatch =>
  new Promise(resolve => resolve(dispatch(addApplicationDateSuccess(applicationDate))))

const saveApplicationDatesStart = () => ({
  type: SAVE_APPLICATION_DATE_START,
})

const saveApplicationDatesError = () => ({
  type: SAVE_APPLICATION_DATE_ERROR,
})

const saveApplicationDatesSuccess = () => ({
  type: SAVE_APPLICATION_DATE_SUCCESS,
})

export const saveApplicationDates = applicationDates => dispatch => {
  dispatch(saveApplicationDatesStart())
  return new Promise((resolve, reject) =>
    orderService
      .saveApplicationDates(applicationDates)
      .then(response => {
        const { data } = response.data
        dispatch(saveApplicationDatesSuccess(data))
        resolve(data)
      })
      .catch(e => {
        dispatch(saveApplicationDatesError())
        reject(e)
      }),
  )
}

/* --------- Clear --------- */
export const CLEAR_ORDERS = 'CLEAR_ORDERS'

const setClearOrders = () => ({
  type: CLEAR_ORDERS,
})

export const clearOrders = () => dispatch =>
  new Promise(resolve => resolve(dispatch(setClearOrders())))

/* --------- Select --------- */
export const SET_SELECTED_ORDER = 'SET_SELECTED_ORDER'
export const SET_SELECTED_ORDER_PIVOT = 'SET_SELECTED_ORDER_PIVOT'
export const SET_SELECTED_ORDER_FARM = 'SET_SELECTED_ORDER_FARM'
export const SET_SELECTED_ORDER_PRODUCT = 'SET_SELECTED_ORDER_PRODUCT'
export const UPDATE_SELECTED_ORDER_APPLICATION_DATE = 'UPDATE_SELECTED_ORDER_APPLICATION_DATE'

const selectedOrder = order => ({
  type: SET_SELECTED_ORDER,
  order,
})

export const setSelectedOrder = order => dispatch =>
  new Promise(resolve => resolve(dispatch(selectedOrder(order))))

const selectedPivot = pivot => ({
  type: SET_SELECTED_ORDER_PIVOT,
  pivot,
})

export const setSelectedPivot = pivot => dispatch =>
  new Promise(resolve => resolve(dispatch(selectedPivot(pivot))))

const selectedProduct = product => ({
  type: SET_SELECTED_ORDER_PRODUCT,
  product,
})

export const setSelectedProduct = product => dispatch =>
  new Promise(resolve => resolve(dispatch(selectedProduct(product))))

export const updateSelectedOrderApplicationDate = applicationDate => ({
  type: UPDATE_SELECTED_ORDER_APPLICATION_DATE,
  applicationDate,
})

export const selectedFarm = farm => ({
  type: SET_SELECTED_ORDER_FARM,
  farm,
})

export const setSelectedFarm = farm => dispatch =>
  new Promise(resolve => resolve(dispatch(selectedFarm(farm))))
