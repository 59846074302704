import { 
  SET_HOME_TAB, 
  SET_ORDER_FILTERS, 
  SET_OPEN_MODAL, 
  SET_MODAL_FILTER_OPEN,
  SET_MODAL_FILTER_CLOSE

} from '../actions/ui'

const initialState = {
  tab: 0,
  orderFilters: {},
  isFilterModalOpen: false,
  modal: {
    changeProfileByChangeManager: {
      isOpenModal: false,
    },
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_HOME_TAB:
      return { ...state, tab: action.tab }
    
    case SET_ORDER_FILTERS:
      return { ...state, orderFilters: action.orderFilters }

    case SET_OPEN_MODAL: {
      const openModal = { ...state.modal }
      openModal[action.modalName].isOpenModal = action.isOpenModal

      return { ...state, ...openModal }
    }

    case SET_MODAL_FILTER_OPEN: {
      return { ...state, isFilterModalOpen: true }
    }
    
    case SET_MODAL_FILTER_CLOSE: {
      return { ...state, isFilterModalOpen: false }
    }

    default:
      return state
  }
}
