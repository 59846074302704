import styles from '../styles/exports.scss'

export const CORTEVA_WORKFLOW_LOCAL_STORAGE = 'CORTEVA_WORKFLOW_LOCAL_STORAGE'
export const PROFILE_TOKEN = 'PROFILE_TOKEN'

export const PROFILE_GENERAL_ADM = 1
export const PROFILE_TECHNICIAN = 4

export const COLOR_LIGHT = styles.colorLight
export const COLOR_SELECT = styles.colorSelect
export const COLOR_PRIMARY = styles.colorPrimary

export const APPLICATION_TYPES = [
  { value: 0, label: 'Aplicação Terrestre' },
  { value: 1, label: 'Aplicação Aérea' },
  { value: 2, label: 'Aplicação Pivô Central' },
]

export const MONTHS = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
]
export const DAY_NAMES = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']

export const MODAL_NAME = {
  changeProfileByChangeManager: 'changeProfileByChangeManager',
}
