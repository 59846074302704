import React from 'react'

import './EmptyScreen.scss'

export default ({ icon, title, description }) => (
  <div className="empty-screen">
    <img className="empty-screen__img" src={icon} alt="Nuvem" />
    <h1 className="empty-screen__title">{title}</h1>
    <p className="empty-screen__body">{description}</p>
  </div>
)
