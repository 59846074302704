import { isDesktopDevice } from 'utils/layout'

export const farmsListHeaders = [
    {id: 0, name: 'Nome das fazendas'},
    {id: 1, name: 'Pivôs ativos'}
]

export const filterFarms = (farm, searchText) => farm.name.toLowerCase().includes(searchText.toLowerCase())

export const formatPivotsMessage = (numberPivots) => {
    const isPluralMessage = numberPivots > 1
    if(isDesktopDevice()) {
        return isPluralMessage ?  `${numberPivots} pivôs` : `${numberPivots} pivô`
    }
     return isPluralMessage? `${numberPivots} pivôs ativos` : `${numberPivots} pivô ativo`
}