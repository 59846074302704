import React from 'react'
import RSlider from 'rc-slider/lib/Slider'

import 'rc-slider/assets/index.css'
import './Slider.scss'

export default props => (
  <div className="slider">
    <RSlider {...props} />
  </div>
)
