import React from 'react'
import { connect } from 'react-redux'
import { CART_FEEDBACK_ROUTE, PRODUCTS_ROUTE } from 'routes/consts'
import { setSelectedProduct, clearCart, toggleCartOpen } from 'store/actions/cart'
import { addOfflineOrder } from 'store/actions/order'
import { updateStock } from 'store/actions/product'
import { createGuid } from 'services/utils'
import productService from 'services/product'
import keyCodes from 'utils/keyCodes'
import arrowUp from 'assets/vectors/arrow-up.svg'
import { ReactComponent as Close } from 'assets/vectors/times.svg'
import EmptyState from './EmptyState/EmptyState'
import ProductList from './ProductList/ProductList'
import StockModal from './StockModal/StockModal'
import ResaleStockModal from './ResaleStockModal/ResaleStockModal'
import PrimaryButton from '../PrimaryButton/PrimaryButton'
import HideOnKeyboardUp from '../HideOnKeyboardUp/HideOnKeyboardUp'

import './Cart.scss'

class Cart extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      openStockModal: false,
      openResaleStockModal: false,
      isRequestingNewOrder: false,
    }

    this.productsWithStock = []
    this.productsWithoutResaleStock = []
  }

  componentDidMount() {
    document.addEventListener('keyup', e => {
      if (e.keyCode === keyCodes.ESC && this.props.isOpen) {
        this.toggleState()
      }
    })
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', e => {
      if (e.keyCode === keyCodes.ESC && this.props.isOpen) {
        this.toggleState()
      }
    })
  }

  async handleSubmit(products) {
    function getHarvestChimicalOrSelectHarvest(allProducts, selectedHarvestFromSelect) {
      const firstOrder = allProducts[0] || null
      const harvestPivotIndex = Object.keys(allProducts[0].pivots)
      const harvestIdFromFirstOrder = firstOrder?.pivots[harvestPivotIndex[0]]?.harvestId || null

      return harvestIdFromFirstOrder || selectedHarvestFromSelect.id
    }

    const { router, selectedFarm, selectedNucleus, selectedHarvest, chemicalResales } = this.props
    const productsWithRequestedVolume = []
    this.productsWithStock = []
    this.productsWithoutResaleStock = []

    const chimicalHarvestOrSelectHarvestID = getHarvestChimicalOrSelectHarvest(
      products,
      selectedHarvest,
    )

    products.forEach(p => {
      const requestedVolume = productService.getStocksQuantity(
        chemicalResales[p.id].chemicalStocks,
        Object.values(p.pivots),
        p.usedStock || 0,
      )
      productsWithRequestedVolume.push({ ...p, requestedVolume })

      if (p.stock && p.stock.actual && !p.ignoreStock && !p.usedStock)
        this.productsWithStock.push(p)

      if (chemicalResales[p.id].stocks - requestedVolume.requestedVolume < 0)
        this.productsWithoutResaleStock.push({ ...p, requestedVolume })
    })

    if (this.productsWithStock.length > 0) {
      this.verifyStocks()
    } else if (this.productsWithoutResaleStock.length > 0) {
      this.verifyResaleStocks()
    } else {
      this.setState({ isRequestingNewOrder: true })
      productsWithRequestedVolume.forEach(p =>
        this.props.updateStock(
          p.id,
          chemicalResales[p.id].stocks - p.requestedVolume.requestedVolume,
        ),
      )

      await this.props.addOfflineOrder({
        chemicals: products,
        date: new Date(),
        guid: createGuid(),
        farmId: selectedFarm.id,
        farmName: selectedFarm.name,
        nucleusId: selectedNucleus.id,
        harvestId: chimicalHarvestOrSelectHarvestID,
        user: { id: this.props.profile.id, name: this.props.profile.name }
      })
      setTimeout(() => {
        this.props.clearCart()
        this.setState({ isRequestingNewOrder: false })
      }, 1000) // Não aparecer o carrinho vazio ao trocar de tela
      router.navigate({ name: CART_FEEDBACK_ROUTE })
    }
  }

  toggleState() {
    this.props.toggleCart()
  }

  verifyStocks() {
    if (this.productsWithStock.length > 0) {
      this.props.setSelectedProduct(this.productsWithStock.shift())
      this.setState({ openStockModal: true })
    }
  }

  verifyResaleStocks() {
    if (this.productsWithoutResaleStock.length > 0) {
      this.props.setSelectedProduct(this.productsWithoutResaleStock.shift())
      this.setState({ openResaleStockModal: true })
    }
  }

  render() {
    const {
      cart,
      router,
      selectedFarm,
      selectedNucleus,
      selectedHarvest,
      selectedPivot,
      isOpen,
    } = this.props
    const { isRequestingNewOrder } = this.state
    const products = Object.values(cart)
    const cartLength = products.length
    const isEmpty = cartLength === 0

    return (
      <HideOnKeyboardUp>
        {/* eslint-disable-next-line */}
        {isOpen ? <div className="cart__background" onClick={() => this.toggleState()} /> : null}
        <div className={`cart ${isOpen ? 'cart--desktop-open' : ''}`}>
          {/* eslint-disable-next-line */}
          <div
            className={`cart__tab ${isOpen ? 'cart__tab--open' : ''}`}
            onMouseUp={() => this.toggleState()}
            onTouchEnd={() => this.toggleState()}
          >
            <div
              className={`cart__tab__quantity-container ${
                !isOpen ? 'cart__tab__quantity-container--close' : ''
              }`}
            >
              <p className="cart__tab__quantity">{cartLength}</p>
            </div>
            <p className={`cart__tab__text ${!isOpen ? 'cart__tab__text--close' : ''}`}>Produtos</p>
            <img className="cart__tab__icon" src={arrowUp} alt="Menu" />
          </div>
          <div className={`cart__content ${!isOpen ? 'cart__content--close' : ''}`}>
            <div className="cart__content__header">
              <button
                className="desktop-only header__button-close"
                onClick={() => this.toggleState()}
              >
                <Close />
              </button>
              <p className="cart__content__header__nucleus-harvest">{`${selectedNucleus.name} - ${selectedHarvest.name}`}</p>
              <h1 className="cart__content__header__farm">{selectedFarm.name}</h1>
            </div>
            <div className="cart__content__body">
              <div
                className={`cart__content__body__list ${
                  isEmpty ? 'cart__content__body__list--empty' : ''
                }`}
              >
                {isEmpty ? <EmptyState /> : <ProductList products={products} router={router} />}
                {/* eslint-disable-next-line */}
                <p
                  className="cart__content__body__close"
                  onClick={() => {
                    if (selectedPivot && selectedFarm.pivots.find(p => p.id === selectedPivot.id))
                      router.navigate({ name: PRODUCTS_ROUTE })
                    this.toggleState()
                  }}
                >{`Adicionar${isEmpty ? '' : ' mais'} produtos`}</p>
              </div>
              {!isEmpty && (
                <PrimaryButton
                  className="cart__content__body__finish"
                  onClick={() => this.handleSubmit(products)}
                  disabled={isRequestingNewOrder || this.props.isAdm}
                  isLoading={isRequestingNewOrder}
                >
                  Concluir pedido
                </PrimaryButton>
              )}
            </div>
          </div>
        </div>
        <StockModal
          open={this.state.openStockModal}
          close={() => {
            this.setState({ openStockModal: false })
            this.verifyStocks()
          }}
        />
        <ResaleStockModal
          open={this.state.openResaleStockModal}
          close={() => {
            this.setState({ openResaleStockModal: false })
          }}
        />
      </HideOnKeyboardUp>
    )
  }
}

const mapStateToProps = state => ({
  cart: state.cart.cart,
  isOpen: state.cart.isCartOpen,
  selectedNucleus: state.nucleus.selected,
  selectedFarm: state.farm.selected,
  selectedHarvest: state.harvest.selected,
  selectedPivot: state.pivot.selected,
  chemicalResales: state.product.chemicalResales,
  isAdm: state.auth.profileChangeByGeneralAdm,
  profile: state.auth.profile,
})

const mapDispatchToProps = dispatch => ({
  setSelectedProduct: product => dispatch(setSelectedProduct(product)),
  addOfflineOrder: order => dispatch(addOfflineOrder(order)),
  clearCart: () => dispatch(clearCart()),
  updateStock: (id, stocks) => dispatch(updateStock(id, stocks)),
  toggleCart: () => dispatch(toggleCartOpen()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Cart)
