/* --------- Tab --------- */
export const SET_HOME_TAB = 'SET_HOME_TAB'

const homeTab = tab => ({
  type: SET_HOME_TAB,
  tab,
})

export const setHomeTab = tab => dispatch => new Promise(resolve => resolve(dispatch(homeTab(tab))))

/* --------- Filters --------- */
export const SET_MODAL_FILTER_OPEN = 'SET_MODAL_FILTER_OPEN'

const setModalFilterOpen = () => ({
  type: SET_MODAL_FILTER_OPEN,
})

export const SET_MODAL_FILTER_CLOSE = 'SET_MODAL_FILTER_CLOSE'

const setModalFilterClose = () => ({
  type: SET_MODAL_FILTER_CLOSE,
})

export const setModalFilter = isFilterModalOpen => dispatch =>
  new Promise(resolve => {
    if(isFilterModalOpen)
      resolve(dispatch(setModalFilterOpen()))
    else resolve(dispatch(setModalFilterClose()))
  })

export const SET_ORDER_FILTERS = 'SET_ORDER_FILTERS'

const setOrderFiltersSuccess = orderFilters => ({
  type: SET_ORDER_FILTERS,
  orderFilters,
})

export const setOrderFilters = orderFilters => dispatch =>
  new Promise(resolve => resolve(dispatch(setOrderFiltersSuccess(orderFilters))))

export const SET_OPEN_MODAL = 'SET_OPEN_MODAL'

export const setModalOpenOrClose = ({ modalName = '', isOpenModal = false }) => ({
  type: SET_OPEN_MODAL,
  modalName,
  isOpenModal,
})
