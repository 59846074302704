import React from 'react'
import Helmet from 'react-helmet'
import { Link, Toolbar, Tabs, Tab, Page } from 'framework7-react'
import { connect } from 'react-redux'
import {
  CartLayout,
  HomeHeader,
  EmptyScreen,
  HideOnKeyboardUp,
  Layout,
  CartModal,
} from 'components'
import cloudIcon from 'assets/vectors/cloud.svg'
import { fetchHarvests } from 'store/actions/harvest'
import { getNetworkStatus } from 'store/actions/network'
import { saveOrders, saveApplicationDates } from 'store/actions/order'
import { setSelectedNucleus } from 'store/actions/nucleus'
import { setHomeTab } from 'store/actions/ui'
import rootPages from 'config/rootPages'
import insertOrRemoveSelectedUserIdIntoQuery from 'utils/insertOrRemoveSelectedUserIdIntoQuery'
import { setSelectedFarm } from 'store/actions/farm'
import { PIVOTS_ROUTE } from 'routes/consts'
import { clearCart } from 'store/actions/cart'
import ChangeProfileByGneralAdmModal from './components/ChangeProfileByGeneralAdmModal'
import './Home.scss'

class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpenCardModal: false,
      selectedFarm: null,
      isCheckingNetworkStatus: true,
    }
  }

  componentDidMount() {
    this.fetchInfo()
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedUserId !== prevProps.selectedUserId) {
      this.fetchInfo()
    }
  }

  handleOnOpenAbandonCartModal = farm => {
    this.setState({ isOpenCardModal: true, selectedFarm: farm })
  }

  fetchInfo() {
    this.props.getNetworkStatus().then(isOnline => {
      if (isOnline) {
        const currentUrl = this.$f7router.history.slice(-1)[0]
        const canFetchHarvests = !currentUrl || currentUrl === '/home' || currentUrl === '/'

        const query = insertOrRemoveSelectedUserIdIntoQuery({
          selectedUserId: this.props.selectedUserId,
        })

        if (canFetchHarvests) {
          this.props.fetchHarvests(isOnline, query)
        }
        if (this.props.applicationDates.length > 0) {
          this.props.saveApplicationDates(this.props.applicationDates)
        }
        if (this.props.allOfflineOrders.length > 0) {
          this.props
            .saveOrders(this.props.allOfflineOrders)
            .then(() => this.props.fetchHarvests(isOnline, query))
        }
      }
      this.setState({ isCheckingNetworkStatus: false })
    })
  }

  goToPivots(farm) {
    this.props.setSelectedFarm(farm)
    this.$f7router.navigate({ name: PIVOTS_ROUTE })
  }

  renderNavLinks() {
    const isActive = tab => this.props.selectedTab === tab.id
    return rootPages.map(tab => (
      <Link
        key={tab.path}
        tabLink={`#${tab.path}`}
        tabLinkActive={isActive(tab)}
        onClick={() => {
          this.props.setHomeTab(tab.id)
        }}
      >
        <img src={tab.icon} alt={tab.label} />
        <span>{tab.label}</span>
      </Link>
    ))
  }

  render() {
    const { isOnline, selectedTab, cart, selectedCartFarm } = this.props
    const cartLength = Object.values(cart).length
    const isActive = tab => selectedTab === tab.id
    const { isOpenCardModal, selectedFarm, isCheckingNetworkStatus } = this.state

    return (
      <Page pageContent={false} className="tabs">
        <Layout>
          <HideOnKeyboardUp>
            <Toolbar noShadow tabbar position="bottom">
              {this.renderNavLinks()}
            </Toolbar>
          </HideOnKeyboardUp>
          <Tabs>
            {rootPages.map(tab => {
              const TabContent = tab.component

              return (
                <Tab
                  key={tab.path}
                  className="page-content"
                  id={tab.path}
                  tabActive={isActive(tab)}
                >
                  <Helmet title="Corteva Workflow" />
                  <CartLayout isLoading={isCheckingNetworkStatus}>
                    <HomeHeader
                      isOnline={isOnline}
                      hideNucleus={tab.hideNucleus}
                      isReportHeader={tab.isReportHeader}
                    />
                    <div className="home-page-body">
                      {isOnline ? (
                        <TabContent
                          {...this.props}
                          router={this.$f7router}
                          openAbandonCartModal={this.handleOnOpenAbandonCartModal}
                        />
                      ) : (
                        <EmptyScreen
                          icon={cloudIcon}
                          title="Você está offline"
                          description="Não foi possível carregar as informações. Verifique que você está conectado a internet."
                        />
                      )}
                    </div>
                  </CartLayout>
                </Tab>
              )
            })}
          </Tabs>
        </Layout>
        <CartModal
          open={isOpenCardModal}
          cartLength={cartLength}
          onContinue={() => {
            this.setState({ isOpenCardModal: false })
            this.goToPivots(selectedCartFarm)
          }}
          onCloseModal={() => {
            this.setState({ isOpenCardModal: false })
          }}
          onAbandon={() =>
            this.props.clearCart().then(() => {
              this.setState({ isOpenCardModal: false })
              this.goToPivots(selectedFarm)
            })
          }
        />
        <ChangeProfileByGneralAdmModal />
      </Page>
    )
  }
}

const mapStateToProps = state => ({
  allOfflineOrders: state.order.allOfflineOrders,
  applicationDates: state.order.applicationDates,
  selectedNucleus: state.nucleus.selected,
  selectedReport: state.report.selectedReport,
  selectedTab: state.ui.tab,
  isOnline: state.network.isOnline,
  downloadedHarvestId: state.harvest.downloadedId,
  cart: state.cart.cart,
  selectedCartFarm: state.farm.selected,
  selectedUserId: state.auth.generalAdminIntoProfileId,
})

const mapDispatchToProps = dispatch => ({
  fetchHarvests: (isOnline, query) => dispatch(fetchHarvests(isOnline, query)),
  getNetworkStatus: () => dispatch(getNetworkStatus()),
  saveOrders: offlineOrders => dispatch(saveOrders(offlineOrders)),
  saveApplicationDates: applicationDates => dispatch(saveApplicationDates(applicationDates)),
  setSelectedNucleus: selectedNucleus => dispatch(setSelectedNucleus(selectedNucleus)),
  setHomeTab: tab => dispatch(setHomeTab(tab)),
  setSelectedFarm: farm => dispatch(setSelectedFarm(farm)),
  clearCart: () => dispatch(clearCart()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Home)
