import { APPLICATION_TYPES } from '../config/consts'

const getProductFromNucleus = (nucleusId, allProducts) =>
  allProducts.reduce((a, p) => {
    const chemicalResales = p.chemicalResales.filter(c => c.nucleusId === nucleusId)
    if (chemicalResales && chemicalResales.length > 0) a.push({ ...p, chemicalResales })
    return a
  }, [])

const getStocksQuantity = (chemicalStocks, pivots, usedStock) => {
  const stocksDictionary = {}
  let useStock = true

  APPLICATION_TYPES.forEach(applicationType => {
    let quantity = pivots.reduce(
      (a, p) => (p.applicationType.value === applicationType.value ? a + p.quantity : a),
      0,
    )

    if (quantity > 0 && useStock) {
      quantity -= usedStock
      useStock = false
    }

    if (quantity > 0) {
      const stocks = chemicalStocks.sort((a, b) => b.volume - a.volume)
      const { length } = stocks
      const finalStocks = {}

      for (let i = 0; i < length; i++) {
        const stock = stocks[i]

        if (quantity - stock.volume > 0 || (i === length - 1 && quantity > 0)) {
          quantity -= stock.volume
          if (finalStocks[stock.id]) finalStocks[stock.id].quantity++
          else finalStocks[stock.id] = { ...stock, quantity: 1 }
          i--
        }
      }

      stocksDictionary[applicationType.value] = Object.values(finalStocks)
    }
  })

  const returnStocks = Object.values(stocksDictionary)

  return {
    stocks: stocksDictionary,
    requestedVolume: returnStocks.reduce(
      (acc, stock) =>
        acc +
        stock.reduce((a, chemicalStock) => a + chemicalStock.quantity * chemicalStock.volume, 0),
      0,
    ),
  }
}

const getPreferenceResale = chemicalResales => {
  let resultChemicalResales = chemicalResales
    .filter(c => c.stocks > 0)
    .sort((a, b) => a.preference - b.preference)

  if (resultChemicalResales.length <= 0)
    resultChemicalResales = chemicalResales.sort((a, b) => a.preference - b.preference)

  return resultChemicalResales[0]
}

export default { getProductFromNucleus, getStocksQuantity, getPreferenceResale }
