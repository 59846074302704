import React from 'react'

import './Header.scss'

export default ({ centered, white, title, content }) => (
  <article
    className={`header ${centered ? 'header--centered' : ''} ${white ? 'header--white' : ''}`}
  >
    <h1 className="header__title">{title}</h1>
    <p className="header__content">{content}</p>
  </article>
)
