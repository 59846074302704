import harvestService from '../../services/harvest'

import { fetchNucleusByHarvestId } from './nucleus'
import { fetchOrdersByHarvestId, clearOrders } from './order'

/* --------- Select --------- */
export const SET_SELECTED_HARVEST = 'SET_SELECTED_HARVEST'
export const SET_DOWNLOADED_HARVEST = 'SET_DOWNLOADED_HARVEST'

const selectedHarvest = harvest => ({
  type: SET_SELECTED_HARVEST,
  harvest,
})

const setDownloadedHarvest = harvestId => ({
  type: SET_DOWNLOADED_HARVEST,
  harvestId,
})

export const setSelectedHarvest = (harvest, isOnline, query = {}) => dispatch =>
  new Promise(resolve => {
    dispatch(clearOrders())
    if (isOnline) {
      dispatch(fetchNucleusByHarvestId(harvest.id, query))
      dispatch(fetchOrdersByHarvestId(harvest.id, query))
      dispatch(setDownloadedHarvest(harvest.id))
    }
    dispatch(selectedHarvest(harvest))
    resolve()
  })

/* --------- Fetch All --------- */
export const FETCH_HARVESTS_START = 'FETCH_HARVESTS_START'
export const FETCH_HARVESTS_ERROR = 'FETCH_HARVESTS_ERROR'
export const FETCH_HARVESTS_SUCCESS = 'FETCH_HARVESTS_SUCCESS'

export const fetchHarvestsStart = () => ({
  type: FETCH_HARVESTS_START,
})

const fetchHarvestsError = () => ({
  type: FETCH_HARVESTS_ERROR,
})

const fetchHarvestsSuccess = harvests => ({
  type: FETCH_HARVESTS_SUCCESS,
  harvests,
})

export const fetchHarvests = (isOnline, query) => dispatch => {
  dispatch(fetchHarvestsStart())
  return new Promise((resolve, reject) =>
    harvestService
      .getActive()
      .then(response => {
        const { data } = response.data
        dispatch(fetchHarvestsSuccess(data))
        if (data) dispatch(setSelectedHarvest(data[0], isOnline, query))
        resolve()
      })
      .catch(e => {
        dispatch(fetchHarvestsError())
        reject(e)
      }),
  )
}
