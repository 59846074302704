import {
  FETCH_HARVESTS_START,
  FETCH_HARVESTS_ERROR,
  FETCH_HARVESTS_SUCCESS,
  SET_SELECTED_HARVEST,
  SET_DOWNLOADED_HARVEST,
} from '../actions/harvest'

const initialState = {
  selected: null,
  downloadedId: null,
  harvests: [],
  isLoadingHarvests: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_HARVESTS_SUCCESS:
      return {
        ...state,
        isLoadingHarvests: false,
        harvests: action.harvests,
      }

    case SET_SELECTED_HARVEST:
      return { ...state, isLoadingHarvests: false, selected: action.harvest }

    case SET_DOWNLOADED_HARVEST:
      return { ...state, downloadedId: action.harvestId }

    case FETCH_HARVESTS_START:
      return { ...state, isLoadingHarvests: true }

    case FETCH_HARVESTS_ERROR:
    default:
      return { ...state, isLoadingHarvests: false }
  }
}
