import {
  FETCH_NUCLEUS_START,
  FETCH_NUCLEUS_ERROR,
  FETCH_NUCLEUS_SUCCESS,
  SET_SELECTED_NUCLEUS,
} from '../actions/nucleus'

const initialState = {
  selected: null,
  nucleus: [],
  isLoadingNucleus: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_NUCLEUS_SUCCESS:
      return { ...state, nucleus: action.nucleus }

    case SET_SELECTED_NUCLEUS:
      return { ...state, selected: action.nucleus, isLoadingNucleus: false }

    case FETCH_NUCLEUS_START:
      return { ...state, isLoadingNucleus: true}
    case FETCH_NUCLEUS_ERROR:
      return { ...state, isLoadingNucleus: false}
    default:
      return state
  }
}