import React from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as Arrow } from 'assets/vectors/chevron-right.svg'
import { Link } from 'framework7-react'

import './Breadcrumb.scss'

const Breadcrumb = ({ items, className }) => (
  <ul className={`breadcrumb ${className}`}>
    {items.map((item, index) => (
      <li key={item.name}>
        {item.route ? <Link href={item.route}>{item.name}</Link> : item.name}{' '}
        {items.length !== index + 1 ? <Arrow /> : null}
      </li>
    ))}
  </ul>
)

Breadcrumb.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  className: PropTypes.string,
}

Breadcrumb.defaultProps = {
  className: '',
}

export default Breadcrumb
