import React from 'react'
import PropTypes from 'prop-types'

import './ContainerHeader.scss'

const ContainerHeader = ({ items }) => (
  <ul className="container-header">
    {items.map(item => (
      <li
        className={`container-header__item ${
          item.selected ? 'container-header__item--selected' : ''
        }`}
        {...item}
      />
    ))}
  </ul>
)

ContainerHeader.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default ContainerHeader
