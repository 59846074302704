import React from 'react'
import { Link } from 'framework7-react'

import { ReactComponent as ArrowIcon } from '../../assets/vectors/arrow.svg'
import { ReactComponent as TimesIcon } from '../../assets/vectors/times.svg'

import './Navbar.scss'

export default ({ router, className, blue, backButton, left, center, right, isModal }) => {
  const renderBackButton = () =>
    router.history.length > 0 && (
      <Link
        style={{ verticalAlign: 'middle' }}
        onClick={() => {
          router.back()
        }}
        className={isModal ? 'navbar__close-modal' : ''}
      >
        {isModal ? <TimesIcon /> : <ArrowIcon className="navbar__left__arrow" />}
      </Link>
    )

  return (
    <div className={`navbar ${className || ''} ${blue ? 'navbar--blue' : ''}`}>
      <div className="navbar__left">
        {backButton && router ? renderBackButton() : <span />}
        {left}
      </div>
      <div className="navbar__center navbar__title">{center}</div>
      <div className="navbar__right">{right}</div>
    </div>
  )
}
