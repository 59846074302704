import { MONTHS } from '../config/consts'

export const pad = (n, nPad = 2) => `${'0'.repeat(nPad - 1)}${n}`.slice(nPad * -1)

export const getChemicalOrder = (resaleOrders, chemical) =>
  resaleOrders.reduce((aCo, ro) => {
    const chemicalOrder = ro.chemicalOrders.find(co => co.chemicalId === chemical.chemicalId)

    if (ro.applicationType === chemical.applicationType) return chemicalOrder || aCo

    return aCo || chemicalOrder
  }, null)

export const translateDate = rawDate => {
  const date = rawDate ? new Date(rawDate) : new Date()
  return `${pad(date.getDate())} de ${MONTHS[date.getMonth()]}`
}

export const dateToBr = rawDate => {
  const date = new Date(rawDate)
  return `${pad(date.getDate())}/${pad(date.getMonth() + 1)}/${date.getFullYear()}`
}

export const createGuid = () =>
  ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    // eslint-disable-next-line
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16),
  )
