import React from 'react'
import Loader from '../Loader/Loader'

import './CartLayout.scss'

export default ({ withCart, children, isLoading = false }) => {
  if (isLoading) {
    return (
      <div className="page-loading">
        <Loader />
      </div>
    )
  }
  return (
    <article className={`app-layout ${withCart ? 'app-layout--with-cart' : ''}`}>
      {children}
    </article>
  )
}
