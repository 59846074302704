import {
  CHANGE_PASSWORD_ROUTE,
  FORGOT_PASSWORD_EMAIL_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  HOME_ROUTE,
  LOGIN_ROUTE,
  LOGIN_ERROR_ROUTE,
  UNSUPPORTED_ROLE_ROUTE,
  PIVOTS_ROUTE,
  PRODUCTS_ROUTE,
  CONFIG_PRODUCT_ROUTE,
  PRODUCT_PIVOTS_ROUTE,
  CART_FEEDBACK_ROUTE,
  ORDER_PRODUCTS_ROUTE,
  ORDER_APPLICATION_ROUTE,
  ORDER_PIVOTS_ROUTE,
  ORDER_LIST_ROUTE,
  ORDER_CANCEL_ROUTE,
} from './consts'

import {
  Login,
  LoginError,
  ForgotPassword,
  ForgotPasswordEmailSent,
  ChangePassword,
  UnsupportedRole,
  Home,
  Pivots,
  Products,
  ConfigProduct,
  CartPivots,
  CartFeedback,
  OrderProducts,
  OrderApplication,
  OrderPivots,
  OrderList,
  OrderCancel,
} from '../screens'

import redirect from '../services/redirect'
import statusbarService from '../services/statusbar'
import reducers from '../store'

const { store } = reducers
const checkAuth = async (to, from, resolve) => {
  const accessToken = await store.getState().auth.token
  if (accessToken) {
    resolve()
  } else {
    window.location.pathname = '/login'
  }
}

const routes = [
  {
    path: '/login',
    name: LOGIN_ROUTE,
    component: Login,
    on: {
      pageBeforeIn: () => statusbarService.useSecondaryBackground(),
    },
  },
  {
    path: '/login-error',
    name: LOGIN_ERROR_ROUTE,
    component: LoginError,
    on: {
      pageBeforeIn: () => statusbarService.useSecondaryBackground(),
    },
  },
  {
    path: '/unsupported-role',
    name: UNSUPPORTED_ROLE_ROUTE,
    component: UnsupportedRole,
    on: {
      pageBeforeIn: () => statusbarService.useSecondaryBackground(),
    },
  },
  {
    path: '/forgot-password',
    name: FORGOT_PASSWORD_ROUTE,
    component: ForgotPassword,
    on: {
      pageBeforeIn: () => statusbarService.useSecondaryBackground(),
    },
  },
  {
    path: '/forgot-password-email-sent',
    name: FORGOT_PASSWORD_EMAIL_ROUTE,
    component: ForgotPasswordEmailSent,
    on: {
      pageBeforeIn: () => statusbarService.useSecondaryBackground(),
    },
  },
  {
    path: '/change-password',
    name: CHANGE_PASSWORD_ROUTE,
    component: ChangePassword,
    // beforeEnter: checkAuth,
    on: {
      pageBeforeIn: () => statusbarService.useSecondaryBackground(),
    },
  },
  {
    path: '/home',
    name: HOME_ROUTE,
    component: Home,
    beforeEnter: checkAuth,
    on: {
      pageBeforeIn: () => statusbarService.useSecondaryBackground(),
    },
  },
  {
    path: '/pivots',
    name: PIVOTS_ROUTE,
    component: Pivots,
    beforeEnter: checkAuth,
    on: {
      pageBeforeIn: () => statusbarService.usePrimaryBackground(),
    },
  },
  {
    path: '/products',
    name: PRODUCTS_ROUTE,
    component: Products,
    beforeEnter: checkAuth,
    on: {
      pageBeforeIn: () => statusbarService.useSecondaryBackground(),
    },
  },
  {
    path: '/config-product',
    name: CONFIG_PRODUCT_ROUTE,
    component: ConfigProduct,
    beforeEnter: checkAuth,
    on: {
      pageBeforeIn: () => statusbarService.usePrimaryBackground(),
    },
  },
  {
    path: '/product-pivots',
    name: PRODUCT_PIVOTS_ROUTE,
    component: CartPivots,
    beforeEnter: checkAuth,
    on: {
      pageBeforeIn: () => statusbarService.usePrimaryBackground(),
    },
  },
  {
    path: '/cart-feedback',
    name: CART_FEEDBACK_ROUTE,
    component: CartFeedback,
    beforeEnter: checkAuth,
    on: {
      pageBeforeIn: () => statusbarService.usePrimaryBackground(),
    },
  },
  {
    path: '/order-products',
    name: ORDER_PRODUCTS_ROUTE,
    component: OrderProducts,
    beforeEnter: checkAuth,
    on: {
      pageBeforeIn: () => statusbarService.useSecondaryBackground(),
    },
  },
  {
    path: '/order-pivots',
    name: ORDER_PIVOTS_ROUTE,
    component: OrderPivots,
    beforeEnter: checkAuth,
    on: {
      pageBeforeIn: () => statusbarService.useSecondaryBackground(),
    },
  },
  {
    path: '/order-list',
    name: ORDER_LIST_ROUTE,
    component: OrderList,
    beforeEnter: checkAuth,
    on: {
      pageBeforeIn: () => statusbarService.useSecondaryBackground(),
    },
  },
  {
    path: '/order-application',
    name: ORDER_APPLICATION_ROUTE,
    component: OrderApplication,
    beforeEnter: checkAuth,
    on: {
      pageBeforeIn: () => statusbarService.usePrimaryBackground(),
    },
  },
  {
    path: '/order-cancel',
    name: ORDER_CANCEL_ROUTE,
    component: OrderCancel,
    beforeEnter: checkAuth,
    on: {
      pageBeforeIn: () => statusbarService.usePrimaryBackground(),
    },
  },
]

const rootPageName = redirect.getRootPage()
const rootPage = routes.filter(page => page.name === rootPageName)[0]
routes.push(
  /* Root page */
  {
    path: '/',
    name: 'Main',
    component: rootPage.component,
  },
)

export default routes
