import React, { useState } from 'react'
import { ListInput } from 'framework7-react'

import iconEye from '../../assets/vectors/eye.svg'
import iconEyeSlash from '../../assets/vectors/eye-slash.svg'

import './PrimaryInput.scss'

export default ({ className, placeholder, hideLabel, isPassword, type, ...props }) => {
  const [showPassword, setShowPassword] = useState(false)
  const [inputType, setInputType] = useState(type)

  function handleShowPassword() {
    setInputType(showPassword ? 'password' : 'text')
    setShowPassword(prevState => !prevState)
  }

  function showPasswordIcon() {
    if (isPassword) {
      if (!showPassword) {
        return (
          <button
            fill
            className="eye-password-button no-ripple"
            type="button"
            onClick={handleShowPassword}
          >
            <img className="eye-password-button__eye-icon " src={iconEye} alt="Show password" />
          </button>
        )
      }

      return (
        <button
          className="eye-password-button no-ripple"
          type="button"
          onClick={handleShowPassword}
        >
          <img className="eye-password-button__eye-icon" src={iconEyeSlash} alt="hide password" />
        </button>
      )
    }
    return null
  }

  const eyePassword = showPasswordIcon()

  return (
    <div className={`${isPassword ? 'primary-input__container__input-mask' : ''}`}>
      <ListInput
        {...props}
        className={`input-primary ${className || ''}`}
        colorTheme="blue"
        type={inputType}
        outline
        label={hideLabel? null: placeholder}
        placeholder={placeholder}
      />
      {eyePassword}
    </div>
  )
}
