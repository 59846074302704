import React from 'react'
import Helmet from 'react-helmet'
import { Page } from 'framework7-react'
import { connect } from 'react-redux'
import {
  Content,
  Header,
  PrimaryButton,
  Form,
  Footer,
  LogoWithIcon,
  Navbar,
} from 'components'
import { login } from 'store/actions/auth'
import './LoginError.scss'

class LoginError extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {}

  goBack() {
    this.$f7router.back()
  }

  render() {
    const pageTitle = 'Corteva Workflow: Falha na autenticação'
    return (
      <Page className="welcome-layout unsupported-role-page">
        <Helmet title={pageTitle} />
        <Navbar center={<div>Credenciais inválidas</div>} />
        <Content>
          <Form>
            <LogoWithIcon />
            <Header
              title="Houve uma falha na autenticação"
              content="Por favor, verifique suas credenciais e tente novamente"
              centered
            />
          </Form>
        </Content>
        <Footer>
          <PrimaryButton
            fill
            color="primary"
            onClick={() => {
              this.goBack()
            }}
          >
            Ok
          </PrimaryButton>
        </Footer>
      </Page>
    )
  }
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  login: user => dispatch(login(user)),
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginError)
