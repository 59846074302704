import { SET_OFFLINE, SET_ONLINE } from '../actions/network'

const initialState = {
  isOnline: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_OFFLINE:
      return { ...state, isOnline: false }
    case SET_ONLINE:
      return { ...state, isOnline: true }
    default:
      return state
  }
}
