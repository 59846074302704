import React from 'react'
import PropTypes from 'prop-types'
import keyCodes from 'utils/keyCodes'
import arrow from 'assets/vectors/arrow.svg'
import { OrderPageType } from 'utils/ordersPageMessage'
import PageTypeFarmOrPivot from './PageTypeFarmOrPivot/PageTypeFarmOrPivot'
import PageTypeOrder from './PageTypeOrder/PageTypeOrder'
import { getPivotMessage } from './helpers'
import './OrderTableItem.scss'

const OrderTableItem = ({item, lineClick, orderPageType}) => (
  <li
    className="order-table-item"
  >
    <div
      className="order-table-item__text-container"
      role="button"
      tabIndex={0}
      onKeyDown={e => {
        if (e.keyCode === keyCodes.ENTER) {
          lineClick(item)
        }
      } }
      onClick={() => lineClick(item)}
    >
      {orderPageType === OrderPageType.farm || orderPageType === OrderPageType.pivot ?
        <PageTypeFarmOrPivot
          name={orderPageType === OrderPageType.pivot? getPivotMessage(item.name) : item.name}
          isMissingApplicationDate={item.isMissingApplicationDate}
          isAnyChemicalCanceled={item.isAnyChemicalCanceled} 
        />
        :
        <PageTypeOrder 
          item={item}   
        />
      }
      <img className="order-table-item__text-container--arrow" src={arrow} alt="Seta" />
    </div>
  </li>
)

OrderTableItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    isMissingApplicationDate: PropTypes.bool,
    isAnyChemicalCanceled: PropTypes.bool,
    pivots: PropTypes.objectOf.isRequired,
    chemicals: PropTypes.arrayOf(PropTypes.object)
  }).isRequired,
  lineClick: PropTypes.func.isRequired
}

export default OrderTableItem