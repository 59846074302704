import farmService from '../../services/farm'

import { setOrders, setOfflineOrders } from './order'

/* --------- Farms --------- */
export const SET_FARMS = 'SET_FARMS'

const setFarmsSuccess = farms => ({
  type: SET_FARMS,
  farms,
})

export const setFarms = nucleus => (dispatch, getState) =>
  new Promise(resolve => {
    let farms = []
    const { order } = getState()

    if (nucleus) farms = farmService.getFarmFromNucleus(nucleus)

    if (order.allOrders) dispatch(setOrders(farms, order.allOrders))

    if (order.allOfflineOrders) dispatch(setOfflineOrders(farms, order.allOfflineOrders))

    resolve(dispatch(setFarmsSuccess(farms)))
  })

/* --------- Select --------- */
export const SET_SELECTED_FARM = 'SET_SELECTED_FARM'

const selectedFarm = farm => ({
  type: SET_SELECTED_FARM,
  farm,
})

export const setSelectedFarm = farm => dispatch =>
  new Promise(resolve => resolve(dispatch(selectedFarm(farm))))
