import {
  ADD_PRODUCT_TO_CART,
  REMOVE_PRODUCT_FROM_CART,
  SET_SELECTED_CART_PRODUCT,
  SET_SELECTED_CART_PIVOT,
  CLEAR_CART,
  CLEAR_SELECTED_CART,
  TOGGLE_CART_OPEN,
} from '../actions/cart'

const initialState = {
  selected: null,
  selectedPivot: null,
  cart: {},
  isCartOpen: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_CART_PRODUCT:
      return { ...state, selected: action.product }

    case SET_SELECTED_CART_PIVOT:
      return { ...state, selectedPivot: action.pivot }

    case ADD_PRODUCT_TO_CART:
    case REMOVE_PRODUCT_FROM_CART:
      return { ...state, cart: action.cart }

    case CLEAR_CART:
      return { ...state, cart: {} }

    case CLEAR_SELECTED_CART:
      return { ...state, selected: null, selectedPivot: null }
    case TOGGLE_CART_OPEN:
      return { ...state, isCartOpen: !state.isCartOpen }
    default:
      return state
  }
}
