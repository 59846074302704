const existsAnyChemicalCanceled = (chemicalOrders) => chemicalOrders.some(c => c.status === '1')

const isMissingApplicationDate = (chemicals) => chemicals.some(chemical => chemical.applicationDate === null)

export const checkChemicalCanceled = (pivots) => {
  let existsChemicalCanceled = false
  for(const pivot of pivots) {
    if(pivot.orders) {
      const { orders } = pivot
      for(const order of orders) {
        if(order.resaleOrders)
        {  
          const { resaleOrders } = order
          for(const resaleOrder of resaleOrders) {
            if(existsAnyChemicalCanceled(resaleOrder.chemicalOrders)) existsChemicalCanceled = true 
          }
        }
      }
    }
  }

  return existsChemicalCanceled
}

export const checkChemicalApplicationDate = (pivots) => {
  let existsChemicalWithoutApplicationDate = false
  for(const pivot of pivots) {
    if(pivot.orders){
      const { orders } = pivot
      for(const order of orders) {
        const { chemicals } = order
        if(isMissingApplicationDate(chemicals)) existsChemicalWithoutApplicationDate = true 
      }
    }
  }
  return existsChemicalWithoutApplicationDate
}