import React from 'react'
import PropTypes from 'prop-types'
import dangerIcon from 'assets/vectors/danger-icon.svg'
import './PageTypeFarmOrPivot.scss'

const PageTypeFarmOrPivot = ({name, isMissingApplicationDate, isAnyChemicalCanceled}) => (
  <div className='page-farm-pivot'>
    <h1 className="page-farm-pivot--name">{name}</h1>
    {isMissingApplicationDate ?
      <p className="page-farm-pivot--aplication-date">Falta indicar data de aplicação</p>
      :
      null}
    {isAnyChemicalCanceled ? (
      <img src={dangerIcon} alt="Aviso" className="page-farm-pivot--cancel-icon" />
    ) :
      null}
  </div>
)

PageTypeFarmOrPivot.propTypes = {
  name: PropTypes.string.isRequired, 
  isMissingApplicationDate: PropTypes.bool.isRequired, 
  isAnyChemicalCanceled: PropTypes.bool.isRequired
}

export default PageTypeFarmOrPivot