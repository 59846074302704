import { SET_SELECTED_FARM, SET_FARMS } from '../actions/farm'

const initialState = {
  selected: null,
  farms: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_FARM:
      return { ...state, selected: action.farm }

    case SET_FARMS:
      return { ...state, farms: action.farms }

    default:
      return state
  }
}
