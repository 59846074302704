import networkService from '../../services/network'

export const SET_ONLINE = 'SET_ONLINE'
export const SET_OFFLINE = 'SET_OFFLINE'

const setOnline = () => ({
  type: SET_ONLINE,
})

const setOffline = () => ({
  type: SET_OFFLINE,
})

export const getNetworkStatus = () => dispatch =>
  new Promise(async resolve => {
    const isConnected = await networkService.isConnected()
    if (isConnected) {
      dispatch(setOnline())
      resolve(true)
    } else {
      dispatch(setOffline())
      resolve(false)
    }
  })
