
import unityService from 'services/unity'

const ReportTypes = { 
  reentryReport: 'reentryReport', 
  orderReport: 'orderReport'
}

export const SET_REPORT_DATA = 'SET_REPORT_DATA'
export const SET_SELECTED_REPORT = 'SET_SELECTED_REPORT'
export const GENERATE_REPORT_START = 'GENERATE_REPORT_START'
export const GENERATE_REPORT_SUCCESS = 'GENERATE_REPORT_SUCCESS'
export const GENERATE_REPORT_ERROR = 'GENERATE_REPORT_ERROR'

export const generateReportStart = () => ({
  type: GENERATE_REPORT_START,
})

const selectedReport = (report) => ({
  type: SET_SELECTED_REPORT,
  report,
})

export const setSelectedReport = (report) => dispatch =>
  new Promise(resolve => {
    resolve(dispatch(selectedReport(report)))
  })


const setReportData = (reports) => ({
  type: SET_REPORT_DATA,
  reports,
})

const generateReportSuccess = () => ({
  type: GENERATE_REPORT_SUCCESS,
})

const generateReportError = () => ({
  type: GENERATE_REPORT_ERROR,
})

export const generateReportPageData = (nucleus, unityId) => dispatch =>
  new Promise((resolve, reject) => {
    unityService
    .getUnityById(unityId)
    .then(response => {
      const { data } = response.data
      const reports = [
        {id: 0, name: 'Relatório de reentrada', reportType: ReportTypes.reentryReport, cards: [...nucleus]},
        {id: 1, name: 'Relatório de pedidos', reportType: ReportTypes.orderReport, cards: [{...data}]}
      ]
      dispatch(setReportData(reports))
      dispatch(setSelectedReport(reports[0]))
      dispatch(generateReportSuccess())
      resolve(reports)
    })
    .catch(e => {
      dispatch(generateReportError())
      reject(e)
    })
  })

