import { setAllProducts, setProducts } from './product'
import { setFarms } from './farm'

import nucleusService from '../../services/nucleus'
import { generateReportPageData, generateReportStart } from './report'

/* --------- Select --------- */
export const SET_SELECTED_NUCLEUS = 'SET_SELECTED_NUCLEUS'

const selectedNucleus = nucleus => ({
  type: SET_SELECTED_NUCLEUS,
  nucleus,
})

export const setSelectedNucleus = nucleus => dispatch =>
  new Promise(resolve => {
    dispatch(setFarms(nucleus))
    if (nucleus) dispatch(setProducts(nucleus.id))
    resolve(dispatch(selectedNucleus(nucleus)))
  })

/* --------- Fetch All By NucleusId --------- */
export const FETCH_NUCLEUS_START = 'FETCH_NUCLEUS_START'
export const FETCH_NUCLEUS_ERROR = 'FETCH_NUCLEUS_ERROR'
export const FETCH_NUCLEUS_SUCCESS = 'FETCH_NUCLEUS_SUCCESS'

export const fetchNucleusStart = () => ({
  type: FETCH_NUCLEUS_START,
})

const fetchNucleusError = () => ({
  type: FETCH_NUCLEUS_ERROR,
})

const fetchNucleusSuccess = nucleus => ({
  type: FETCH_NUCLEUS_SUCCESS,
  nucleus,
})

export const fetchNucleusByHarvestId = (harvestId, query) => (dispatch, getState) => {
  dispatch(fetchNucleusStart())
  dispatch(generateReportStart())
  return new Promise((resolve, reject) =>
    nucleusService
      .getForTechnician(harvestId, query)
      .then(response => {
        const {
          nucleus: { selected },
        } = getState()

        const { data } = response.data
        dispatch(fetchNucleusSuccess(data.nucleus))
        if (data.nucleus?.length > 0) {
          dispatch(generateReportPageData(data.nucleus, data.nucleus[0]?.unitiesId))
        }
        if (data.chemicals) dispatch(setAllProducts(data.chemicals))
        if (data.nucleus) {
          let nucleus = data.nucleus[0]

          if (selected) {
            const findSelectedNucleus = data.nucleus.find(item => item.id === selected.id)
            if (findSelectedNucleus) {
              nucleus = findSelectedNucleus
            }
          }
          dispatch(setSelectedNucleus(nucleus))
        }
        resolve(data)
      })
      .catch(e => {
        dispatch(fetchNucleusError())
        reject(e)
      }),
  )
}
