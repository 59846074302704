import { CORTEVA_WORKFLOW_LOCAL_STORAGE } from '../config/consts'

const REDUX_PERSIST_KEY = `persist:${CORTEVA_WORKFLOW_LOCAL_STORAGE}`

const getState = () => JSON.parse(localStorage.getItem(REDUX_PERSIST_KEY) || '{}')

const setState = state => localStorage.setItem(REDUX_PERSIST_KEY, JSON.stringify(state || {}))

const clearState = () => localStorage.removeItem(REDUX_PERSIST_KEY)

export default { getState, setState, clearState }
