import homeIcon from 'assets/vectors/tab-icon-home.svg'
import  FarmList  from 'screens/FarmList/FarmList'
import reportsIcon from 'assets/vectors/tab-icon-reports.svg'
import OrderFarms  from 'screens/Orders/OrderFarms/OrderFarms'
import Reports from 'screens/Reports/Reports'
import ordersIcon from 'assets/vectors/tab-icon-orders.svg'

export default [
  {
    id: 0,
    path: 'start',
    label: 'Início',
    icon: homeIcon,
    hideNucleus: false,
    isReportHeader: false,
    component: FarmList,
  },
  {
    id: 1,
    path: 'reports',
    label: 'Relatórios',
    icon: reportsIcon,
    hideNucleus: true,
    isReportHeader: true,
    component: Reports,
  },
  {
    id: 2,
    path: 'orders',
    label: 'Pedidos',
    icon: ordersIcon,
    hideNucleus: false,
    isReportHeader: false,
    component: OrderFarms,
  },
]
