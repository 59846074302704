import axios from 'axios'
import config from '../config'

const axiosPrivateInstance = axios.create({
  withCredentials: true,
  baseURL: config.baseUrl,
  headers: {
    'Content-Type': 'application/json'
  }
})

export default axiosPrivateInstance
