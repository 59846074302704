import { isMissingApplicationDate } from 'utils/ordersPageMessage'
import { filterByTerm, sortInDateOrder } from 'utils/sortFunctions'
import { isAnyFilterSelected } from 'utils/validations'

export const orderListHeaders = [
  { id: 0, name:'Nº PEDIDO' }, 
  { id: 1, name:'STATUS' }, 
  { id: 2, name:'TÉCNICO RESPONSÁVEL' }
]

export const createBreadcrumbItems = (nucleusName, farmName, pivotName) => 
[
  {
    name: nucleusName,
    route: '/home',
  },
  {
    name: farmName,
    route: '/order-pivots',
  },
  {
    name: pivotName,
  },
]

export const filterOrder = (orders, searchText, orderFilters) => {
  const { hasApplicationDate, lackApplicationDate } = orderFilters || {}
  let filteredOrders = []
  
  const ordersWithoutApplicationDate = sortInDateOrder(orders.filter(order => isMissingApplicationDate(order)))
  const ordersWithApplicationDate = sortInDateOrder(orders.filter(order => !isMissingApplicationDate(order)))
  const newOrders = [...ordersWithoutApplicationDate, ...ordersWithApplicationDate]
  
  if(orderFilters && isAnyFilterSelected(orderFilters)){
    if(lackApplicationDate){
      filteredOrders.push(...ordersWithoutApplicationDate)
    }
    if(hasApplicationDate){
      filteredOrders.push(...ordersWithApplicationDate)
    }
  }

  else {
    filteredOrders =  newOrders
  }
  return filterByTerm(filteredOrders, searchText, 'ORDER_PAGE')
}