import {
    SET_SELECTED_REPORT,
    SET_REPORT_DATA,
    GENERATE_REPORT_START,
    GENERATE_REPORT_SUCCESS,
    GENERATE_REPORT_ERROR
  } from '../actions/report'
  
  const initialState = {
    listOfReports: [],
    selectedReport: {},
    isLoading: false
  }
  
  export default (state = initialState, action) => {
    switch (action.type) {
      case SET_REPORT_DATA:
        return { ...state, listOfReports: action.reports }
      case SET_SELECTED_REPORT:
        return { ...state, selectedReport: action.report }
      case GENERATE_REPORT_START:
        return { ...state, isLoading: true }
      case GENERATE_REPORT_SUCCESS:
        return { ...state, isLoading: false }
      case GENERATE_REPORT_ERROR:
        return initialState
      default:
        return state
    }
  }