import React from 'react'

import { ReactComponent as CloseIcon } from 'assets/vectors/times.svg'
import dangerIcon from '../../assets/vectors/danger-icon.svg'

import './Modal.scss'

export default ({ className, children, open, hideIcon, onCloseModal, closeModalByFunction }) => {
  const [isOpen, setIsOpen] = React.useState(open)

  React.useEffect(() => {
    setIsOpen(open)
  }, [open])

  return isOpen ? (
    <div className="modal">
      <div className="modal__background" />
      <div className={`modal__content ${className || ''}`}>
        <button
          className="modal__close"
          onClick={() => {
            if (!closeModalByFunction) {
              setIsOpen(!isOpen)
            }

            if (onCloseModal) {
              onCloseModal()
            }
          }}
        >
          <CloseIcon />
        </button>
        {!hideIcon && <img src={dangerIcon} alt="Aviso" className="modal__content__sign" />}
        {children}
      </div>
    </div>
  ) : null
}
