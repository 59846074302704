import authService from './auth'

import { HOME_ROUTE, LOGIN_ROUTE } from '../routes/consts'

const getRootPage = () => {
  const user = authService.getLoggedInUser()
  if (!user || !user.profileId) return LOGIN_ROUTE
  return HOME_ROUTE
}

export default { getRootPage }
