import React from 'react'
import Helmet from 'react-helmet'
import { Page } from 'framework7-react'
import { connect } from 'react-redux'
import { CONFIG_PRODUCT_ROUTE } from 'routes/consts'
import { CartLayout, DefaultHeader, CardList } from 'components'
import { setSelectedPivot } from 'store/actions/cart'
import './CartPivots.scss'

class CartPivots extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    const { selected } = this.props
    const pageTitle = 'Corteva Workflow: Pivôs de Produtos'

    return (
      <Page className="cart-pivots-page">
        <Helmet title={pageTitle} />
        <CartLayout>
          <DefaultHeader
            router={this.$f7router}
            title={selected.name}
            beforeTitle="Edição de pedido"
          />
          <div className="cart-pivots-page__body">
            <h1 className="cart-pivots-page__body__title">Escolha o pivô para edição</h1>
            <CardList
              cards={Object.values(selected.pivots)}
              description={p => `${p.quantity} ${selected.unitMeasure}`}
              onSelect={p => {
                this.props.setSelectedPivot(p)
                this.$f7router.navigate({ name: CONFIG_PRODUCT_ROUTE }, { reloadCurrent: true })
              }}
            />
          </div>
        </CartLayout>
      </Page>
    )
  }
}

const mapStateToProps = state => ({
  selected: state.cart.selected,
})

const mapDispatchToProps = dispatch => ({
  setSelectedPivot: pivot => dispatch(setSelectedPivot(pivot)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CartPivots)
