export const status = [
  'Enviado para a revenda',
  'Cancelado',
  'Faturado',
  'Usado do Estoque',
  'Aceito',
]
export const CHEMICAL_ORDER_STATUS = {
  received: '0',
  canceled: '1',
  billed: '2',
  stock: '3',
  accepted: '4',
  requestCancelInvoice: '5',
}
