import { isDesktopDevice } from 'utils/layout'

export const getPivotMessage = (pivotKey) => isDesktopDevice()? pivotKey : `Pivô nº ${pivotKey}`

export const getTechnicianMessage = (technician) => isDesktopDevice()? technician : `Técnico responsável: ${technician}`

export const getOrderNumberMessage = (orderId) => isDesktopDevice()? orderId : `Nº pedido ${orderId}`

export const getOrderStatusMessage = (orderId) => orderId ? 'Pedido enviado para revenda' : 'Pedido offline'

export const isMissingApplicationDate = (order) => order?.chemicals.some(chemical => chemical.applicationDate === null)

export const anyChemicalOrderWasCanceled = (resaleOrders) => 
  resaleOrders?.find(r => r.chemicalOrders.find(c => c.status === '1'))