import cartService from '../../services/cart'

/* --------- Add --------- */
export const ADD_PRODUCT_TO_CART = 'ADD_PRODUCT_TO_CART'

const addProductToCart = cart => ({
  type: ADD_PRODUCT_TO_CART,
  cart,
})

export const addToCart = (product, pivot) => (dispatch, getState) =>
  new Promise(resolve => {
    const { cart } = getState().cart
    const newCart = cartService.addToCart(product, pivot, cart)
    resolve(dispatch(addProductToCart(newCart)))
  })

/* --------- Remove --------- */
export const REMOVE_PRODUCT_FROM_CART = 'REMOVE_PRODUCT_FROM_CART'

const removeProductFromCart = cart => ({
  type: REMOVE_PRODUCT_FROM_CART,
  cart,
})

export const removeFromCart = (productId, pivotId) => (dispatch, getState) =>
  new Promise(resolve => {
    const { cart } = getState().cart
    const newCart = cartService.removeFromCart(productId, pivotId, cart)
    resolve(dispatch(removeProductFromCart(newCart)))
  })

/* --------- Clear --------- */
export const CLEAR_CART = 'CLEAR_CART'
export const CLEAR_SELECTED_CART = 'CLEAR_SELECTED_CART'

const clearAllCart = () => ({
  type: CLEAR_CART,
})

export const clearCart = () => dispatch => new Promise(resolve => resolve(dispatch(clearAllCart())))

const clearSelectedCart = () => ({
  type: CLEAR_SELECTED_CART,
})

export const clearSelected = () => dispatch =>
  new Promise(resolve => resolve(dispatch(clearSelectedCart())))

/* --------- Select --------- */
export const SET_SELECTED_CART_PRODUCT = 'SET_SELECTED_CART_PRODUCT'
export const SET_SELECTED_CART_PIVOT = 'SET_SELECTED_CART_PIVOT'

const selectedProduct = product => ({
  type: SET_SELECTED_CART_PRODUCT,
  product,
})

export const setSelectedProduct = product => dispatch =>
  new Promise(resolve => resolve(dispatch(selectedProduct(product))))

const selectedPivot = pivot => ({
  type: SET_SELECTED_CART_PIVOT,
  pivot,
})

export const setSelectedPivot = pivot => dispatch =>
  new Promise(resolve => resolve(dispatch(selectedPivot(pivot))))

/* --------- Open/Close Cart --------- */

export const TOGGLE_CART_OPEN = 'TOGGLE_CART_OPEN'

export const toggleCartOpen = () => ({
  type: TOGGLE_CART_OPEN,
})
