import {
  setNewAccessTokenError,
  setNewAccessTokenStart,
  setNewAccessTokenSuccess
} from 'store/actions/auth'
import config from '../config'
import axiosPrivateInstance from './AxiosInstance'

const AxiosInterceptors = store => {
  axiosPrivateInstance.interceptors.request.use(async instanceConfig => {
    const token = await store.getState().auth.token
    if (token) {
      instanceConfig.headers.authorization = `Bearer ${token}`
    }
    return instanceConfig
  })

  axiosPrivateInstance.interceptors.response.use(
    res => res,
    async error => {
      const prevRequest = error?.config
      if (prevRequest.url !== `${config.baseUrl}/login` && error.response) {
        if (error.response.status === 401 && !prevRequest?.sent) {
          prevRequest.sent = true
          try {
            await store.dispatch(setNewAccessTokenStart())
            const refreshTokenResponse = await axiosPrivateInstance.post('/refresh-token')
            const { token: newAccessToken } = refreshTokenResponse.data.data
            await store.dispatch(setNewAccessTokenSuccess(newAccessToken))
            return axiosPrivateInstance(prevRequest)
          } catch (_error) {
            await store.dispatch(setNewAccessTokenError())
            window.location.href = '/login'
            return Promise.reject(_error)
          }
        }
      }
      return Promise.reject(error)
    }
  )
}

export default AxiosInterceptors
