import React from 'react'
import Helmet from 'react-helmet'
import { Page } from 'framework7-react'
import { connect, useDispatch } from 'react-redux'
import { CartLayout, DefaultHeader, CardList, Cart, Layout, ContainerHeader } from 'components'
import { setSelectedPivot } from 'store/actions/pivot'
import { PRODUCTS_ROUTE } from 'routes/consts'

import './Pivots.scss'

function Pivots(props) {
  const dispatch = useDispatch()
  const { selectedNucleus, selectedFarm, selectedHarvest, pivots, f7router } = props
  const pageTitle = 'Corteva Workflow: Pivôs'
  const headerDesktop = [
    {
      key: 1,
      children: 'Pivôs',
      selected: true,
    },
  ]

  const breadcrumbItems = [
    {
      name: selectedNucleus.name,
      route: '/home',
    },
    {
      name: selectedFarm.name,
    },
  ]

  return (
    <Page className="pivots-page">
      <Helmet title={pageTitle} />
      <Layout breadcrumbItems={breadcrumbItems} showCart>
        <CartLayout withCart>
          <DefaultHeader
            router={f7router}
            headerTitle={
              <div className="pivots-page__header">
                <h1 className="pivots-page__header__title">{selectedFarm.name}</h1>
                <p className="pivots-page__header__subtitle">{selectedFarm.cooperativeName}</p>
              </div>
            }
            beforeTitle={`${selectedNucleus.name} - ${selectedHarvest.name}`}
          />
          <div className="pivots-page__body">
            <ContainerHeader items={headerDesktop} />
            <CardList
              cards={pivots}
              description={p => `${p.area} hectares`}
              onSelect={p => {
                dispatch(setSelectedPivot(p))
                f7router.navigate({ name: PRODUCTS_ROUTE })
              }}
            />
          </div>
        </CartLayout>
      </Layout>
      <div slot="fixed">
        <Cart router={f7router} />
      </div>
    </Page>
  )
}

const mapStateToProps = state => ({
  pivots: state.pivot.pivots,
  selectedNucleus: state.nucleus.selected,
  selectedFarm: state.farm.selected,
  selectedHarvest: state.harvest.selected,
})

export default connect(mapStateToProps)(Pivots)
