import React from 'react'
import PropTypes from 'prop-types'
import Checkbox from '../Checkbox/Checkbox'
import './FilterModal.scss'

const RenderItem = ({text, value, checkboxOnChange}) => (
  <li className="filter-modal__item">
    <h3 className="filter-modal__item__title">{text}</h3>
    <Checkbox
      checked={value}
      onChange={checkboxOnChange} />
  </li>
)

RenderItem.propTypes = {
  text: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  checkboxOnChange: PropTypes.func.isRequired
}

export default RenderItem