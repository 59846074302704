import React from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { Page } from 'framework7-react'
import { HOME_ROUTE } from 'routes/consts'
import { Content, Footer, PrimaryButton, SecondaryButton, Navbar, Header } from 'components'
import cartFeedbackIcon from 'assets/vectors/icon-checkbox-negative.svg'
import { setSelectedNucleus } from 'store/actions/nucleus'
import { setHomeTab } from 'store/actions/ui'
import { toggleCartOpen } from 'store/actions/cart'
import './CartFeedback.scss'

class CartFeedback extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const pageTitle = 'Corteva Workflow: Feedback Carrinho'
    return (
      <Page className="cart-feedback">
        <Helmet title={pageTitle} />
        <Navbar blue center={<div>Concluir pedido</div>} />
        <Content blue className="cart-feedback__content">
          <img
            className="cart-feedback__illustration"
            src={cartFeedbackIcon}
            alt="Feedback Carrinho"
          />
          <Header
            white
            title="Você acabou de concluir o seu pedido!"
            content="Seu pedido está sendo processado, em breve você receberá uma notificação com a sua confirmação."
          />
        </Content>
        <Footer>
          <PrimaryButton
            white
            onClick={() => {
              this.props.toggleCartOpen()
              this.$f7router.navigate({ name: HOME_ROUTE })
            }}
          >
            Ok
          </PrimaryButton>
          <SecondaryButton
            blue
            onClick={() => {
              this.props.toggleCartOpen()
              this.props.setSelectedNucleus(this.props.selectedNucleus)
              this.props.setHomeTab(2)
              this.$f7router.navigate({ name: HOME_ROUTE })
            }}
          >
            Histórico de pedidos
          </SecondaryButton>
        </Footer>
      </Page>
    )
  }
}

const mapStateToProps = state => ({
  selectedNucleus: state.nucleus.selected,
})

const mapDispatchToProps = dispatch => ({
  setSelectedNucleus: selectedNucleus => dispatch(setSelectedNucleus(selectedNucleus)),
  setHomeTab: tab => dispatch(setHomeTab(tab)),
  toggleCartOpen: () => dispatch(toggleCartOpen()),
})

export default connect(mapStateToProps, mapDispatchToProps)(CartFeedback)
