import React from 'react'
import { Swiper, SwiperSlide } from 'framework7-react'

import './Swiper.scss'

export default ({ initialValue, onChange, range }) => (
  <Swiper
    params={{
      slidesPerView: 5,
      initialSlide: parseInt(initialValue, 10) - 1,
      centeredSlides: true,
      on: {
        slideChange: () => {
          const { swiper } = document.querySelector('.swiper-container')
          onChange(swiper.activeIndex + 1)
        },
      },
    }}
  >
    {range.map(i => (
      <SwiperSlide key={i}>
        <span className="swiper__content">{i}</span>
      </SwiperSlide>
    ))}
  </Swiper>
)
