import React from 'react'
import Helmet from 'react-helmet'
import { Page } from 'framework7-react'
import { useSelector } from 'react-redux'
import { CartLayout, Navbar, ProductList, Cart, Layout, ContainerHeader } from 'components'
import './Products.scss'


const headerDesktop = [
  {
    key: 1,
    children: 'Produtos',
    selected: true,
  },
]

function Products({ f7router }) {
  const nucleusName = useSelector(({ nucleus }) => nucleus.selected.name)
  const farmName = useSelector(({ farm }) => farm.selected.name)
  const pivotName = useSelector(({ pivot }) => pivot.selected.name)

  const breadcrumbItems = [
    {
      name: nucleusName,
      route: '/home',
    },
    {
      name: farmName,
      route: '/pivots',
    },
    {
      name: pivotName,
    },
  ]

  return (
    <Page className="products-page">
      <Helmet title="Corteva Workflow: Produtos" />
      <Layout breadcrumbItems={breadcrumbItems} showCart>
        <CartLayout withCart>
          <Navbar router={f7router} backButton center={pivotName} />
          <ContainerHeader items={headerDesktop} />
          <div className="products-page__body">
            <ProductList router={f7router} />
          </div>
        </CartLayout>
      </Layout>
      <div slot="fixed">
        <Cart router={f7router} />
      </div>
    </Page>
  )
}

export default Products
