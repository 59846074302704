import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { setSelectedNucleus } from 'store/actions/nucleus'
import logo from 'assets/vectors/logo-small.svg'
import { setSelectedReport } from 'store/actions/report'
import HarvestSelector from '../HarvestSelector/HarvestSelector'
import HeaderSelector from '../HeaderSelector/HeaderSelector'
import './HomeHeader.scss'

const HomeHeader = ({ isOnline, hideNucleus, isReportHeader }) => {
  const {nucleus, selected} = useSelector(state => state.nucleus)
  const {listOfReports, selectedReport} = useSelector(state => state.report)
  

  const canShowNucleusSelector = isOnline && !hideNucleus
  const canShowReportHeader = isOnline && isReportHeader

  return(
  <div className={`home-header ${isOnline ? '' : 'home-header--offline'}`}>
    <div className="home-header__top-container">
      <img src={logo} alt="Logo" />
      <HarvestSelector />
    </div>
    {canShowNucleusSelector ? (
        <div className="home-header__bottom-container">
          <HeaderSelector data={nucleus} dataSelected={selected} functionToDispatch={setSelectedNucleus} />
        </div>
      ): null
    }
    {canShowReportHeader ? (
        <div className="home-header__bottom-container">
          <HeaderSelector data={listOfReports} dataSelected={selectedReport} functionToDispatch={setSelectedReport}/>
        </div>
      ): null
    }
  </div>
  )
}

HomeHeader.propTypes = {
  isOnline: PropTypes.bool.isRequired,
  hideNucleus: PropTypes.bool.isRequired,
  isReportHeader: PropTypes.bool.isRequired,
}

export default HomeHeader
