import React from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as RemoveIcon } from '../../assets/vectors/remove-icon.svg'
import { ReactComponent as AddIcon } from '../../assets/vectors/add-icon.svg'

import './ProductListItem.scss'

const ProductListItem = ({ name, stocks, unitMeasure, handleOnClick, isProductInCart }) => {
  if (stocks <= 0) {
    return null
  }
  return (
    <li
      className="product-list__item"
      role="button"
      aria-label={isProductInCart ? `Remover ${name}` : `Adicionar ${name}`}
      onClick={handleOnClick}
    >
      <div className="product-list__item__text-container">
        <h3 className="product-list__item__name">{name}</h3>
        <p className="product-list__item__stock">{`${stocks} ${unitMeasure} disponível`}</p>
      </div>
      <div className="product-list__item__action">
        {isProductInCart ? <RemoveIcon /> : <AddIcon />}
      </div>
    </li>
  )
}

ProductListItem.propTypes = {
  name: PropTypes.string.isRequired,
  stocks: PropTypes.number.isRequired,
  unitMeasure: PropTypes.string.isRequired,
  handleOnClick: PropTypes.func.isRequired,
  isProductInCart: PropTypes.bool.isRequired,
}

export default ProductListItem
