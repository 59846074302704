import React from 'react'
import { Link } from 'framework7-react'
import { connect } from 'react-redux'
import { CardList, EmptyScreen, Loader } from 'components'
import farmIcon from 'assets/vectors/farm.svg'
import { getReportUrl } from './helpers'

import './Reports.scss'

const Reports = ({ selectedHarvest, selectedReport, token, isLoading }) => {
  const existsData = selectedHarvest && Object.keys(selectedReport).length > 0
  if(isLoading){
    return (
      <div className='page-loading'><Loader/></div>
    )
  }

  if(!existsData) {
    return (
      <EmptyScreen
        icon={farmIcon}
        title="Nenhum relatório encontrado"
        description="Não existe nenhuma fazenda criada até o momento."
      />
    )
  }

  return(
    <div className="reports-page">
      <div className='reports-page__cards'>
        {
          selectedReport.cards.map(card => (
            <Link
              key={card.id}
              href={getReportUrl(selectedHarvest.id, selectedReport.reportType, token, card.id)}
              external
            >
              <CardList cards={[{ name: card.name }]} description={() => 'PDF'} />
            </Link>
          ))
        }
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  selectedHarvest: state.harvest.selected,
  reports: state.report.selectedReport,
  isLoading: state.report.isLoading,
  token: state.auth.token,
})

export default connect(mapStateToProps)(Reports)
