import { connect } from 'react-redux'

const HideOnKeyboardUp = ({ keyboardIsVisible, children }) =>
  keyboardIsVisible ? null : children

const mapStateToProps = state => ({
  keyboardIsVisible: state.keyboard.isVisible,
})

export default connect(mapStateToProps)(HideOnKeyboardUp)
