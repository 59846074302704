import { StatusBar, Style } from '@capacitor/status-bar'

import styles from '../styles/exports.scss'

const hasStatusBar = window.cordova

const setStyle = style => hasStatusBar && StatusBar.setStyle({ style })
const setBackgroundColor = color => hasStatusBar && StatusBar.setBackgroundColor({ color })

const usePrimaryBackground = () => {
  setBackgroundColor(styles.statusBarPrimaryColor)
  setStyle(Style.Dark)
}

const useSecondaryBackground = () => {
  setBackgroundColor(styles.statusBarSecondaryColor)
  setStyle(Style.Light)
}

export default {
  usePrimaryBackground,
  useSecondaryBackground,
}
