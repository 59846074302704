/* --------- Routes --------- */
export const LOGIN_ROUTE = 'Login'
export const LOGIN_ERROR_ROUTE = 'LoginError'
export const UNSUPPORTED_ROLE_ROUTE = 'UnsupportedRole'
export const FORGOT_PASSWORD_ROUTE = 'ForgotPassword'
export const FORGOT_PASSWORD_EMAIL_ROUTE = 'ForgotPasswordEmailSent'
export const CHANGE_PASSWORD_ROUTE = 'ChangePassword'

export const HOME_ROUTE = 'Home'
export const PIVOTS_ROUTE = 'Pivots'
export const PRODUCTS_ROUTE = 'Products'
export const CONFIG_PRODUCT_ROUTE = 'ConfigProduct'
export const PRODUCT_PIVOTS_ROUTE = 'ProductPivots'
export const CART_FEEDBACK_ROUTE = 'CartFeedback'
export const ORDER_PIVOTS_ROUTE = 'OrderPivots'
export const ORDER_LIST_ROUTE = 'OrderList'
export const ORDER_PRODUCTS_ROUTE = 'OrderProducts'
export const ORDER_APPLICATION_ROUTE = 'OrderApplication'
export const ORDER_CANCEL_ROUTE = 'OrderCancel'
