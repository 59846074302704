import React from 'react'
import PropTypes from 'prop-types'
import './HeaderTitle.scss'

const HeaderTitle = ({ title }) => (
  <div className="header-title">
    <h1 className="header-title--text">{title}</h1>
  </div>
)

HeaderTitle.propTypes = {
  title: PropTypes.string.isRequired
}

export default HeaderTitle