import React from 'react'
import { connect } from 'react-redux'

import PrimaryButton from '../../PrimaryButton/PrimaryButton'
import SecondaryButton from '../../SecondaryButton/SecondaryButton'
import Modal from '../../Modal/Modal'
import Swiper from '../../Swiper/Swiper'

import { addToCart } from '../../../store/actions/cart'
import { pad } from '../../../services/utils'
import { isDesktopDevice } from '../../../utils/layout'
import LeftoversNumber from '../../LeftoversNumber/LeftoversNumber'

class StockModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      openAdjustModal: false,
      newStockValue: props.selectedProduct?.stock?.actual || 0,
    }

    const range = [...Array(100).keys()]
    range.shift()
    this.range = range.map(n => pad(n))
  }

  addStockAction = (currentStock, isUpdateStock) => {
    const availableStock = Number(currentStock || 0)
    const quantity = this.props.selectedProduct?.quantity || 0

    const newUsedStock = availableStock >= quantity ? quantity : availableStock
    const newQuantity = availableStock >= quantity ? 0 : quantity - availableStock

    this.props.addToCart({
      ...this.props.selectedProduct,
      quantity: newQuantity,
      usedStock: newUsedStock,
      updateStockTo: isUpdateStock ? availableStock : undefined,
      stock: {
        ...this.props.selectedProduct.stock,
        actual: availableStock,
      },
    })

    this.props.close()
    this.setState({ newStockValue: this.props.selectedProduct?.stock?.actual })
  }

  render() {
    const { open, close, selectedProduct } = this.props

    return selectedProduct && selectedProduct.stock ? (
      <>
        <Modal
          open={open && !this.state.openAdjustModal}
          onCloseModal={() => {
            close()
          }}
        >
          <h1 className="modal__title">Alerta de sobras de químicos</h1>
          <p className="modal__description">
            Notamos que você já possui em estoque na sua fazenda de
            <span className="modal__description__name">{` ${selectedProduct.stock.actual} ${selectedProduct.unitMeasure} `}</span>
            do químico
            <span className="modal__description__name">{` ${selectedProduct.name}`}</span>
          </p>
          <PrimaryButton onClick={() => this.addStockAction(selectedProduct.stock.actual)}>
            Considerar
          </PrimaryButton>
          <SecondaryButton
            onClick={() => {
              this.props.addToCart({ ...selectedProduct, ignoreStock: true })
              close()
            }}
          >
            Ignorar
          </SecondaryButton>
          <SecondaryButton onClick={() => this.setState({ openAdjustModal: true })}>
            Ajustar valor
          </SecondaryButton>
        </Modal>

        <Modal
          open={this.state.openAdjustModal}
          onCloseModal={() => {
            this.setState({
              openAdjustModal: false,
              newStockValue: selectedProduct?.stock?.actual || 0,
            })
            close()
          }}
        >
          <h1 className="modal__title">Ajuste de sobras de químicos</h1>
          <p className="modal__description modal__description--swiper">
            Ajuste o valor real que você possui de estoque do químico
            <span className="modal__description__name">{` ${selectedProduct.name}`}</span>
          </p>
          {isDesktopDevice() ? (
            <LeftoversNumber
              value={this.state.newStockValue}
              onChange={e => this.setState({ newStockValue: e.target.value })}
            />
          ) : (
            <Swiper
              range={this.range}
              initialValue={selectedProduct.stock.actual}
              onChange={newStockValue => this.setState({ newStockValue })}
            />
          )}
          <PrimaryButton
            onClick={() => {
              this.setState({ openAdjustModal: false })
              this.addStockAction(this.state.newStockValue, true)
            }}
          >
            Atualizar valor
          </PrimaryButton>
        </Modal>
      </>
    ) : null
  }
}

const mapStateToProps = state => ({
  selectedPivot: state.cart.selectedPivot,
  selectedProduct: state.cart.selected,
})

const mapDispatchToProps = dispatch => ({
  addToCart: product => dispatch(addToCart(product)),
})

export default connect(mapStateToProps, mapDispatchToProps)(StockModal)
