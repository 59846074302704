import {
  LOGIN_START,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  REQUEST_NEW_PASSWORD_START,
  REQUEST_NEW_PASSWORD_ERROR,
  REQUEST_NEW_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_START,
  CHANGE_PASSWORD_ERROR,
  CHANGE_PASSWORD_SUCCESS,
  GET_PROFILE_START,
  GET_PROFILE_ERROR,
  GET_PROFILE_SUCCESS,
  CHANGE_GENERAL_ADM_PROFILE,
  SET_NEW_ACCESS_TOKEN_ERROR,
  SET_NEW_ACCESS_TOKEN_START,
  SET_NEW_ACCESS_TOKEN_SUCCESS,
  LOGOUT_SUCCESS,
  LOGOUT_START,
  LOGOUT_ERROR
} from '../actions/auth'

const initialState = {
  profile: {},
  profileChangeByGeneralAdm: false,
  generalAdminIntoProfileId: 0,
  token: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PROFILE_SUCCESS:
      return { ...state, profile: action.profile }

    case LOGIN_SUCCESS:
      return { ...state, profile: { profileId: action.profileId }, token: action.token }

    case CHANGE_GENERAL_ADM_PROFILE:
      return {
        ...state,
        profile: { ...state.profile, profileId: action.profileId },
        profileChangeByGeneralAdm: action.profileChangeByGeneralAdm,
        generalAdminIntoProfileId: action.userId,
      }

    case SET_NEW_ACCESS_TOKEN_SUCCESS:
      return { ...state, token: action.token }

    case LOGOUT_SUCCESS:
      return { ...state, profile: null, token: null }

    case SET_NEW_ACCESS_TOKEN_ERROR:
    case SET_NEW_ACCESS_TOKEN_START:
    case LOGOUT_START:
    case LOGOUT_ERROR:
    case LOGIN_START:
    case LOGIN_ERROR:
    case REQUEST_NEW_PASSWORD_START:
    case REQUEST_NEW_PASSWORD_ERROR:
    case REQUEST_NEW_PASSWORD_SUCCESS:
    case CHANGE_PASSWORD_START:
    case CHANGE_PASSWORD_ERROR:
    case CHANGE_PASSWORD_SUCCESS:
    case GET_PROFILE_START:
    case GET_PROFILE_ERROR:
    default:
      return state
  }
}
