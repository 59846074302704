import React from 'react'
import Helmet from 'react-helmet'
import { Page } from 'framework7-react'
import { connect } from 'react-redux'
import {
  CHANGE_PASSWORD_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  UNSUPPORTED_ROLE_ROUTE,
  LOGIN_ERROR_ROUTE,
  HOME_ROUTE,
} from 'routes/consts'
import { PROFILE_TECHNICIAN, PROFILE_GENERAL_ADM, MODAL_NAME } from 'config/consts'
import {
  Content,
  Header,
  PrimaryInput,
  PrimaryButton,
  SecondaryButton,
  Form,
  Footer,
  LogoWithIcon,
  HideOnKeyboardUp,
} from 'components'
import { login, loginSuccess } from 'store/actions/auth'
import { setModalOpenOrClose } from 'store/actions/ui'
import { isValidEmail, isValidPassword } from 'utils/validations'
import reducers from '../../store'
import './Login.scss'

class Login extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      user: { email: '', password: '' },
      isLoading: false,
    }
  }

  componentDidMount() {
    // Check if has token from admin app login
    const { query } = this.props.f7route
    if (query.t) {
      const { t: token, p, fa: firstAccess } = query
      const profileId = Number(p)
      window.history.pushState(null, null, window.location.pathname)
      this.props.loginSuccess(profileId, token)
      this.checkLoginInfo({ token, profileId, firstAccess: firstAccess === '1' })
    }
  }

  onSubmit() {
    this.setState({ isLoading: true })
    const { user } = this.state

    this.props
      .login({ email: user.email, password: user.password })
      .then(async data => {
        this.setState({ isLoading: false })
        await reducers.persistor.persist()
        this.checkLoginInfo(data)
      })
      .catch(() => {
        this.setState({ isLoading: false })
        this.$f7router.navigate({ name: LOGIN_ERROR_ROUTE })
      })
  }

  checkLoginInfo = data => {
    if (![PROFILE_TECHNICIAN, PROFILE_GENERAL_ADM].includes(data.profileId)) {
      this.$f7router.navigate({ name: UNSUPPORTED_ROLE_ROUTE })
    } else {
      if (PROFILE_GENERAL_ADM === data.profileId) {
        this.props.setModalOpenOrClose({
          modalName: MODAL_NAME.changeProfileByChangeManager,
          isOpenModal: true,
        })
      }

      if (data.firstAccess) {
        this.$f7router.navigate({ name: CHANGE_PASSWORD_ROUTE })
      } else {
        this.$f7router.navigate({ name: HOME_ROUTE })
      }
    }
  }

  isValidForm() {
    const { user } = this.state
    const { email, password } = user
    return isValidEmail(email) && isValidPassword(password)
  }

  redirectToForgotPasswordPage() {
    this.$f7router.navigate({ name: FORGOT_PASSWORD_ROUTE })
  }

  render() {
    const pageTitle = 'Corteva Workflow: Entrar'
    const { user } = this.state
    return (
      <Page className="welcome-layout login-page">
        <Helmet title={pageTitle} />
        <Content>
          <Form>
            <LogoWithIcon />
            <HideOnKeyboardUp>
              <Header content="Você faz parte do time de técnicos e gestores agronômos da Corteva Workflow, entre com o seu usuário para ter acesso." />
            </HideOnKeyboardUp>
            <PrimaryInput
              value={user.email}
              onChange={e => {
                this.setState({ user: { ...user, email: e.target.value } })
              }}
              required
              placeholder="E-mail"
              type="email"
            />
            <PrimaryInput
              value={user.password}
              onChange={e => {
                this.setState({ user: { ...user, password: e.target.value } })
              }}
              placeholder="Senha"
              type="password"
              isPassword
            />
          </Form>
        </Content>
        <Footer>
          <HideOnKeyboardUp>
            <PrimaryButton
              fill
              color="primary"
              disabled={!this.isValidForm()}
              isLoading={this.state.isLoading}
              onClick={() => {
                this.onSubmit()
              }}
            >
              Login
            </PrimaryButton>
            <SecondaryButton
              className="login-page__link"
              onClick={() => this.redirectToForgotPasswordPage()}
            >
              Esqueci minha senha
            </SecondaryButton>
          </HideOnKeyboardUp>
        </Footer>
      </Page>
    )
  }
}

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => ({
  login: user => dispatch(login(user)),
  loginSuccess: (profileId, token) => dispatch(loginSuccess({ profileId, token })),
  setModalOpenOrClose: ({ modalName, isOpenModal }) =>
    dispatch(setModalOpenOrClose({ modalName, isOpenModal })),
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)
