import React from 'react'
import PropTypes from 'prop-types'
import { PrimaryInput } from 'components'

const CenterPivot = ({area, areaWidth, handleInputChange}) => (
  <>
    <h1 className="config-product-page__item__name">Área do pivô</h1>
    <p className="config-product-page__item__description">
      Indique a área real de aplicação do pivô.
    </p>
    <PrimaryInput
      className={`pivot-area ${
        area === '' ? '' : 'pivot-area--content'
      } pivot-area--content-w${areaWidth}`}
      value={area}
      onChange={e => handleInputChange(e.target.value)}
      required
      placeholder="Área em hectares"
      type="number"
    />
  </>
)

CenterPivot.propTypes = {
  area: PropTypes.number.isRequired,
  areaWidth: PropTypes.number.isRequired,
  handleInputChange: PropTypes.func.isRequired, 

}
export default CenterPivot