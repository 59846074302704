import React from 'react'

import './LeftoversNumber.scss'

const LeftoversNumber = ({ onChange, value, min = 1, max = 99, props }) => {
  function handleOnChange(e) {
    if (e.target.value === '') {
      onChange(e)
      return
    }
    const number = Number(e.target.value)
    if (!max && !min) {
      onChange(e)
      return
    }

    if (max && min) {
      if (number <= max && number >= min) {
        onChange(e)
      }
      return
    }

    if (max && number <= max) {
      onChange(e)
    }

    if (min && number >= min) {
      onChange(e)
    }
  }

  return (
    <input
      type="number"
      className="leftover-number"
      onChange={handleOnChange}
      value={value}
      min={min}
      max={max}
      {...props}
    />
  )
}

export default LeftoversNumber
