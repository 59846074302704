import React from 'react'
import Helmet from 'react-helmet'
import { Page } from 'framework7-react'
import { useSelector, useDispatch } from 'react-redux'
import { setSelectedPivot } from 'store/actions/order'
import { ORDER_LIST_ROUTE } from 'routes/consts'
import { createPageTitle } from 'utils/createPageTitle'
import { OrderPageType } from 'utils/ordersPageMessage'
import farmIcon from 'assets/vectors/farm.svg'
import { Layout, LayoutOrderPage, OrderTable, DefaultHeader, HeaderTitle, EmptyScreen } from 'components'
import { createBreadcrumbItems, filterPivots, orderPivotsHeaders } from './helpers'
import '../styles.scss'

const OrderPivots = ({ f7router }) => {
  const dispatch = useDispatch()
  const [searchText, setSearchText] = React.useState('')
  const { farmName, pivots }  = useSelector(({order}) => order.selectedFarm)
  const { name: nucleusName }  = useSelector(({nucleus}) => nucleus.selected)

  const handleSelectPivot = pivot => {
    dispatch(setSelectedPivot(pivot))
    f7router.navigate({ name: ORDER_LIST_ROUTE })
  }
  const filteredPivots = filterPivots(pivots, searchText)
  
  return (
    <Page className="order-pivots-page">
      <Helmet title={createPageTitle(`Pivôs da fazenda ${farmName}`)} />
      <Layout breadcrumbItems={createBreadcrumbItems(nucleusName, farmName)}>
        <DefaultHeader 
          router={f7router} 
          headerTitle={<HeaderTitle title={farmName}/>}
          beforeTitle={nucleusName}
        />
        <div className="order-pivots-page__body">
          <LayoutOrderPage tableTitle={farmName} placeholder='Busque pelo nome do pivô' searchText={searchText} setSearchText={setSearchText}>
            {filteredPivots.length > 0 ?
              <OrderTable headers={orderPivotsHeaders} data={filteredPivots} lineClick={handleSelectPivot} orderPageType={OrderPageType.pivot}/>
              :
              (
              <EmptyScreen
                icon={farmIcon}
                title="Nenhum pivô encontrado"
                description=""
              />
            )}
          </LayoutOrderPage>
        </div>
      </Layout>
    </Page>
  )
}

export default OrderPivots
