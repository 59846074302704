import React from 'react'
import { Page } from 'framework7-react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'

import { CartLayout, DefaultHeader, PrimaryButton, PrimaryInput, SecondaryButton } from 'components'
import { isDesktopDevice } from 'utils/layout'
import { APPLICATION_TYPES } from 'config/consts'
import orderService from 'services/order'

import './OrderCancel.scss'
import { HOME_ROUTE } from 'routes/consts'

const HighlightedText = ({ children }) => <span className="highlight">{children}</span>

const OrderCancel = ({ f7router }) => {
  const [reason, setReason] = React.useState('')
  const [errorMessage, setErrorMessage] = React.useState('')

  const selectedProduct = useSelector(state => state.order.selectedProduct)
  const selectedPivot = useSelector(state => state.order.selectedPivot)
  const selectedOrder = useSelector(state => state.order.selected)
  const chemicalResale = useSelector(
    state =>
      state.product.chemicalResales[
        state.order.selectedProduct.chemicalId || state.order.selectedProduct.id
      ],
  )
  const applicationType = APPLICATION_TYPES.find(a => a.value === +selectedProduct.applicationType)
    .label

  const handleSumit = async () => {
    try {
      const resaleOrder = selectedOrder.resaleOrders.find(r => {
        const isSameApplicationType = r.applicationType === selectedProduct.applicationType
        const isSameChemicalId = r.chemicalOrders.find(
          chemicalOrder => chemicalOrder.chemicalId === selectedProduct.chemicalId,
        )

        return isSameApplicationType && isSameChemicalId
      })

      if (!resaleOrder) throw new Error('Resale order not found')

      const chemicalOrder = resaleOrder.chemicalOrders.find(
        c => c.chemicalId === selectedProduct.chemicalId,
      )

      await orderService.requestOrderCancel({ chemicalOrderId: chemicalOrder.id, reason })

      f7router.navigate({ name: HOME_ROUTE }, { reloadAll: true })
    } catch (err) {
      const errorResponse = err?.response?.data

      switch (errorResponse?.status) {
        case 'CANCEL_REQUEST_ALREADY_EXISTS': {
          setErrorMessage('Já existe uma solicitação de cancelamento para este químico')
          break
        }
        default: {
          setErrorMessage('Ocorreu um erro ao tentar solicitar o cancelamento')
          break
        }
      }
    }
  }

  const pageTitle = 'Corteva Workflow: Cancelar Produto'
  return (
    <Page className="order-cancel-page">
      <Helmet title={pageTitle} />
      <CartLayout>
        <DefaultHeader
          router={f7router}
          headerTitle={
            <div className="order-cancel-page__header">
              <h1 className="order-cancel-page__header__title">Cancelar químico do pedido</h1>
              <p className="order-cancel-page__header__subtitle">
                Você tem certeza que deseja cancelar o químico {selectedProduct.name}, do pedido de
                Nº {selectedOrder.id} ?
              </p>
            </div>
          }
          beforeTitle={selectedPivot.name}
          isModal={isDesktopDevice()}
        />
        <div className="order-cancel-page__body">
          <div className="order-cancel-page__body__header">
            <h2>ATENÇÃO!</h2>
            <p>
              Será enviada uma solicitação de cancelamento para o químico{' '}
              <HighlightedText>{selectedProduct.name}</HighlightedText> do pedido de{' '}
              <HighlightedText>Nº {selectedOrder.id}</HighlightedText>, para a revenda:{' '}
              <HighlightedText>{chemicalResale.resale.nickname}</HighlightedText>, após a
              confirmação do cancelamento, o químico{' '}
              <HighlightedText>{selectedProduct.name}</HighlightedText> será removido do pedido, e
              você será notificado via e-mail.
            </p>
          </div>
          <div className="order-cancel-page__body__content">
            <h2>Dados do químico</h2>
            <p className="chemical-info">
              Número do pedido: <strong className="highlight">{selectedOrder.id}</strong>
            </p>
            <p className="chemical-info">
              Pivô: <strong className="highlight">{selectedPivot.name}</strong>
            </p>
            <p className="chemical-info">
              Químico: <strong className="highlight">{selectedProduct.name}</strong>
            </p>
            <p className="chemical-info">
              Dosagem:{' '}
              <strong className="highlight">{`${selectedProduct.dosage.toFixed(3)} ${
                chemicalResale.unitMeasure
              }`}</strong>
            </p>
            <p className="chemical-info">
              Volume:{' '}
              <strong className="highlight">{`${selectedProduct.volume.toFixed(3)} ${
                chemicalResale.unitMeasure
              }`}</strong>
            </p>
            <p className="chemical-info">
              Tipo de aplicação: <strong className="highlight">{applicationType}</strong>
            </p>
          </div>

          {errorMessage && (
            <div className="order-cancel-page__error-message">
              <p>{errorMessage}</p>
            </div>
          )}

          <div className="reason-container">
            <PrimaryInput
              hideLabel
              placeholder="Motivo do cancelamento (opcional)"
              value={reason.trim()}
              onChange={e => setReason(e.target.value.trim())}
            />
          </div>

          <div className="order-cancel-page__body__footer">
            <SecondaryButton
              onClick={() => f7router.back()}
              className="order-cancel-page__body__footer__button order-cancel-page__body__footer__button--cancel"
              text="Voltar"
            />
            <PrimaryButton
              onClick={() => handleSumit()}
              className="order-cancel-page__body__footer__button order-cancel-page__body__footer__button--confirm"
              text="Confirmar"
            />
          </div>
        </div>
      </CartLayout>
    </Page>
  )
}

export default OrderCancel
