const addToCart = (newProduct, oldPivot, oldCart) => {
  const cart = { ...oldCart } // Create new obj
  const pivot = { ...oldPivot } // Create new obj
  const existingProduct = { pivots: {}, ...cart[newProduct.id], ...newProduct } // Create new obj

  delete existingProduct.stocks

  if (pivot.id) existingProduct.pivots[pivot.id] = pivot

  existingProduct.quantity = Object.values(existingProduct.pivots).reduce(
    (a, p) => a + p.quantity,
    0,
  )

  if (existingProduct.usedStock) existingProduct.quantity -= existingProduct.usedStock

  existingProduct.quantity = +existingProduct.quantity.toFixed(2)

  existingProduct.quantity = existingProduct.quantity <= 0 ? 0 : existingProduct.quantity

  cart[existingProduct.id] = existingProduct

  return cart
}

const removeFromCart = (productId, pivotId, oldCart) => {
  const cart = { ...oldCart } // Create new obj
  const existingProduct = cart[productId]
  const existingPivot = existingProduct && existingProduct.pivots[pivotId]

  if (!existingProduct || !existingPivot) return oldCart

  existingProduct.quantity -= existingPivot.quantity
  delete existingProduct.pivots[pivotId]

  existingProduct.quantity = existingProduct.quantity <= 0 ? 0 : existingProduct.quantity

  if (Object.keys(existingProduct.pivots).length === 0) delete cart[productId]

  return cart
}

export default { addToCart, removeFromCart }
