import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { MODAL_NAME } from 'config/consts'
import { setModalOpenOrClose } from '../../../../store/actions/ui'

import Modal from '../../../../components/Modal/Modal'
import ChooseTechnician from './components/ChooseTechnician'

const ChangeProfileByGneralAdmModal = () => {
  const dispatch = useDispatch()
  const { generalAdminIntoProfileId, isOpenModal } = useSelector(({ auth, ui }) => ({
    generalAdminIntoProfileId: auth.generalAdminIntoProfileId,
    isOpenModal: ui.modal.changeProfileByChangeManager.isOpenModal,
  }))

  const handleCloseModal = async () => {
    if (generalAdminIntoProfileId > 0) {
      dispatch(setModalOpenOrClose({ modalName: MODAL_NAME.changeProfileByChangeManager }))
    }
  }

  return (
    <Modal open={isOpenModal} hideIcon closeModalByFunction onCloseModal={handleCloseModal}>
      <ChooseTechnician handleCloseModal={handleCloseModal} />
    </Modal>
  )
}

export default ChangeProfileByGneralAdmModal
