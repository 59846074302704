import axiosPrivateInstance from 'middlewares/AxiosInstance'
import localStorageService from './localstorage'


const getInitials = profile =>
  {
    const separateProfileName = profile.name.split(' ')
    const initials = separateProfileName.length >= 2 ?
      separateProfileName[0].charAt(0) + separateProfileName[1].charAt(0) : separateProfileName[0].charAt(0)

    return initials

  }

const getLoggedInUser = () => {
  const state = localStorageService.getState()
  if (state.auth) {
    const authState = JSON.parse(state.auth)
    return authState.profile || {}
  }
  return {}
}

const login = params => axiosPrivateInstance.post('/login', params)

const getProfile = () => axiosPrivateInstance.get('/profile')

const requestNewPassword = email => axiosPrivateInstance.post(`/request-password`, { email })

const changePassword = password => axiosPrivateInstance.post('/change-password', { password })

const logout = () => axiosPrivateInstance.get('/logout')

export default {
  getLoggedInUser,
  getProfile,
  login,
  requestNewPassword,
  changePassword,
  logout,
  getInitials,
}
