import React from 'react'
import Helmet from 'react-helmet'
import { Page } from 'framework7-react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Layout,
  LayoutOrderPage,
  OrderTable,
  DefaultHeader,
  HeaderTitle,
  FilterModal,
  EmptyScreen,
} from 'components'
import { setSelectedOrder } from 'store/actions/order'
import { setModalFilter, setOrderFilters } from 'store/actions/ui'
import { ORDER_PRODUCTS_ROUTE } from 'routes/consts'
import { createPageTitle } from 'utils/createPageTitle'
import { OrderPageType } from 'utils/ordersPageMessage'
import farmIcon from 'assets/vectors/farm.svg'
import { orderListHeaders, createBreadcrumbItems, filterOrder } from './helpers'
import '../styles.scss'

const OrderList = ({ f7router }) => {
  const dispatch = useDispatch()
  const [searchText, setSearchText] = React.useState('')
  const [lackApplicationDate, setlackApplicationDate] = React.useState(false)
  const [hasApplicationDate, setHasApplicationDate] = React.useState(false)

  const { farmName } = useSelector(({ order }) => order.selectedFarm)
  const { name: pivotName, orders } = useSelector(({ order }) => order.selectedPivot)
  const { name: nucleusName } = useSelector(({ nucleus }) => nucleus.selected)
  const isFilterModalOpen = useSelector(({ ui }) => ui.isFilterModalOpen)
  const orderFilters = useSelector(({ ui }) => ui.orderFilters)

  const filterOptions = [
    {
      text: 'Falta indicação',
      value: lackApplicationDate,
      onChangeValue: setlackApplicationDate,
    },
    {
      text: 'Data aplicada',
      value: hasApplicationDate,
      onChangeValue: setHasApplicationDate,
    },
  ]

  const handleSelectOrder = order => {
    dispatch(setSelectedOrder(order))
    f7router.navigate({ name: ORDER_PRODUCTS_ROUTE })
  }

  const handleFilterButton = () => {
    dispatch(setModalFilter(true))
  }

  const handleAplyFilters = () => {
    dispatch(setOrderFilters({ lackApplicationDate, hasApplicationDate }))
    dispatch(setModalFilter(false))
  }

  const handleClose = () => {
    setlackApplicationDate(false)
    setHasApplicationDate(false)
    dispatch(setOrderFilters({ lackApplicationDate: false, hasApplicationDate: false }))
    dispatch(setModalFilter(false))
  }

  const filteredOrders = filterOrder(orders, searchText, orderFilters)

  return (
    <Page className="order-list-page">
      <Helmet title={createPageTitle(`Pedidos do pivô ${pivotName}`)} />
      <Layout breadcrumbItems={createBreadcrumbItems(nucleusName, farmName, pivotName)}>
        <DefaultHeader
          router={f7router}
          headerTitle={<HeaderTitle title={pivotName} />}
          beforeTitle={farmName}
        />
        <div className="order-list-page__body">
          <LayoutOrderPage
            tableTitle={pivotName}
            placeholder="Busque pelo número do pedido"
            searchText={searchText}
            setSearchText={setSearchText}
            isFilterButton
            filterModalOnClick={() => handleFilterButton()}
          >
            {filteredOrders.length > 0 ? (
              <OrderTable
                headers={orderListHeaders}
                data={filteredOrders}
                lineClick={handleSelectOrder}
                orderPageType={OrderPageType.order}
              />
            ) : (
              <EmptyScreen icon={farmIcon} title="Nenhum pedido encontrado" description="" />
            )}
          </LayoutOrderPage>
        </div>
      </Layout>
      <FilterModal
        open={isFilterModalOpen}
        close={handleClose}
        nameFilters="Data de Aplicação"
        filterOptions={filterOptions}
        handleAplyFilters={handleAplyFilters}
      />
    </Page>
  )
}

export default OrderList
