import {
  SET_SELECTED_ORDER,
  SET_SELECTED_ORDER_FARM,
  SET_SELECTED_ORDER_PIVOT,
  SET_SELECTED_ORDER_PRODUCT,
  ADD_OFFLINE_ORDER,
  SET_OFFLINE_ORDERS,
  SET_ALL_OFFLINE_ORDERS,
  FETCH_ORDERS_ERROR,
  FETCH_ORDERS_START,
  FETCH_ORDERS_SUCCESS,
  SAVE_ORDERS_ERROR,
  SAVE_ORDERS_START,
  SAVE_ORDERS_SUCCESS,
  SET_ORDERS,
  CLEAR_ORDERS,
  ADD_APPLICATION_DATE,
  SAVE_APPLICATION_DATE_ERROR,
  SAVE_APPLICATION_DATE_START,
  SAVE_APPLICATION_DATE_SUCCESS,
  UPDATE_SELECTED_ORDER_APPLICATION_DATE,
} from '../actions/order'

const initialState = {
  selected: null,
  selectedFarm: null,
  selectedPivot: null,
  selectedProduct: null,
  allOrders: [],
  orders: {},
  allOfflineOrders: [],
  offlineOrders: {},
  applicationDates: [],
  isLoading: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_ORDER:
      return { ...state, selected: action.order }

    case SET_SELECTED_ORDER_FARM:
      return { ...state, selectedFarm: action.farm }

    case SET_SELECTED_ORDER_PIVOT:
      return { ...state, selectedPivot: action.pivot }

    case SET_SELECTED_ORDER_PRODUCT:
      return { ...state, selectedProduct: action.product }

    case ADD_OFFLINE_ORDER:
      return { ...state, allOfflineOrders: [...state.allOfflineOrders, action.offlineOrder] }

    case SET_OFFLINE_ORDERS:
      return { ...state, offlineOrders: action.offlineOrders }

    case SET_ALL_OFFLINE_ORDERS:
      return { ...state, allOfflineOrders: action.allOfflineOrders, isLoading: false }

    case FETCH_ORDERS_SUCCESS:
      return { ...state, allOrders: action.allOrders, orders: {} }

    case SET_ORDERS:
      return { ...state, orders: action.orders }

    case CLEAR_ORDERS:
      return { ...state, orders: {}, offlineOrders: {} }

    case ADD_APPLICATION_DATE: {
      const newApplicationDates = state.applicationDates.filter(
        applicationDate =>
          applicationDate.orderPivotChemicalId !== action.applicationDate.orderPivotChemicalId,
      )

      return { ...state, applicationDates: [action.applicationDate, ...newApplicationDates] }
    }
    case SAVE_APPLICATION_DATE_SUCCESS:
      return { ...state, applicationDates: [] }

    case UPDATE_SELECTED_ORDER_APPLICATION_DATE: {
      const chemicals = state.selected.chemicals.map(chemical => {
        if (chemical.id === action.applicationDate.orderPivotChemicalId) {
          return {
            ...chemical,
            applicationDate: action.applicationDate.date,
          }
        }
        return chemical
      })

      const newOrders = state.selectedPivot.orders.map(order => {
        if (order.id === state.selected.id) return { ...order, chemicals }
        return { ...order }
      })

      const newPivots = state.selectedFarm.pivots.map(pivot => {
        if (pivot.id === state.selectedPivot.id) return { ...pivot, orders: newOrders }
        return { ...pivot }
      })

      return {
        ...state,
        selectedProduct: { ...state.selectedProduct, applicationDate: action.applicationDate.date },
        selectedPivot: { ...state.selectedPivot, orders: newOrders },
        selectedFarm: { ...state.selectedFarm, pivots: newPivots },
        selected: { ...state.selected, chemicals },
      }
    }

    case SAVE_ORDERS_SUCCESS:
    case FETCH_ORDERS_ERROR:
      return { ...state, isLoading: false }
    case FETCH_ORDERS_START:
      return { ...state, isLoading: true }
    case SAVE_ORDERS_ERROR:
    case SAVE_ORDERS_START:
    case SAVE_APPLICATION_DATE_ERROR:
    case SAVE_APPLICATION_DATE_START:
    default:
      return state
  }
}
