const watchVisibility = (onKeyboardShow, onKeyboardHide) => {
  window.addEventListener('keyboardWillHide', () => {
    onKeyboardHide()
  })
  window.addEventListener('keyboardWillShow', event => {
    onKeyboardShow()
  })
}

export default { watchVisibility }
