import { getChemicalOrder, dateToBr } from 'services/utils'
import { filterByTerm } from 'utils/sortFunctions'
import { isAnyFilterSelected } from 'utils/validations'

export const createBreadcrumbItems = (nucleusName, farmName, pivotName, orderId) => [
  {
    name: nucleusName,
    route: '/home',
  },
  {
    name: farmName,
    route: '/order-pivots',
  },
  {
    name: pivotName,
    route: '/order-list',
  },
  {
    name: orderId,
  },
]

export const getProducts = (chemicals, products, selectedOrder, applicationDates, date) =>
  chemicals.reduce((accumulator, chemical) => {
    let { applicationDate } = chemical
    const { chemicalId, id } = chemical
    const product = products.find(p => p.id === (chemicalId || id))
    let chemicalOrder = null
    let cancelRequest = null
    if (selectedOrder.resaleOrders) {
      chemicalOrder = getChemicalOrder(selectedOrder.resaleOrders, chemical)
      cancelRequest = chemicalOrder?.cancelRequest
    }

    const setApplicationDate = applicationDates.find(a => a.orderPivotChemicalId === id)
    if (setApplicationDate && !applicationDate) applicationDate = setApplicationDate.date

    accumulator.push({
      ...chemical,
      name: product.name,
      chemicalOrderStatus: chemicalOrder ? chemicalOrder.status : chemicalOrder,
      applicationDate,
      cancelRequest,
      orderDate: dateToBr(date),
    })
    return accumulator
  }, [])

const statusNumber = {
  orderReceived: '0',
  orderCanceled: '1',
  orderBilled: '2',
  orderStock: '3',
  orderAccepted: '4',
}

export const filterProducts = (products, searchText, orderFilters) => {
  let filteredProducts = []
  if (orderFilters && isAnyFilterSelected(orderFilters)) {
    Object.keys(orderFilters).forEach(filter => {
      if (orderFilters[filter]) {
        filteredProducts.push(
          ...products.filter(product => product.chemicalOrderStatus === statusNumber[filter]),
        )
      }
    })
  } else {
    filteredProducts = products
  }

  return filterByTerm(filteredProducts, searchText)
}
