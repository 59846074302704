import { combineReducers, createStore, applyMiddleware, compose } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import hardSet from 'redux-persist/lib/stateReconciler/hardSet'
import thunk from 'redux-thunk'

import { CORTEVA_WORKFLOW_LOCAL_STORAGE } from '../config/consts'

import auth from './reducers/auth'
import farm from './reducers/farm'
import pivot from './reducers/pivot'
import harvest from './reducers/harvest'
import nucleus from './reducers/nucleus'
import keyboard from './reducers/virtualKeyboard'
import product from './reducers/product'
import cart from './reducers/cart'
import network from './reducers/network'
import order from './reducers/order'
import ui from './reducers/ui'
import report from './reducers/report'

const appReducer = combineReducers({
  auth,
  farm,
  harvest,
  nucleus,
  pivot,
  product,
  cart,
  keyboard,
  network,
  order,
  ui,
  report,
})

const persistConfig = {
  key: CORTEVA_WORKFLOW_LOCAL_STORAGE,
  storage,
  blacklist: ['network'],
  stateReconciler: hardSet,
}

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose

const persistedReducer = persistReducer(persistConfig, appReducer)
const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(thunk)))
const persistor = persistStore(store)

export default { store, persistor }
