import React from 'react'
import { ListInput } from 'framework7-react'

import './Checkbox.scss'

export default ({ onChange, checked, name, ...props }) => (
  <div className="my-checkbox__container">
    <ListInput
      type="checkbox"
      checked={checked}
      className={`my-checkbox ${checked ? 'my-checkbox--checked' : ''}`}
      onChange={onChange}
      {...props}
    />
    <span className="my-checkbox__name">{name}</span>
  </div>
)
