import React from 'react'
import { connect } from 'react-redux'

import insertOrRemoveSelectedUserIdIntoQuery from 'utils/insertOrRemoveSelectedUserIdIntoQuery'

import { setSelectedHarvest } from '../../store/actions/harvest'
import { getNetworkStatus } from '../../store/actions/network'

import { ReactComponent as Icon } from '../../assets/vectors/harvest.svg'
import { ReactComponent as Chevron } from '../../assets/vectors/chevron-down.svg'

import './HarvestSelector.scss'

class HarvestSelector extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
    }
  }

  toggleDropdown() {
    const newState = this.state.isOpen
    this.setState({ isOpen: !newState })
  }

  handleClick(item) {
    this.props.getNetworkStatus().then(isOnline => {
      const query = insertOrRemoveSelectedUserIdIntoQuery({
        selectedUserId: this.props.selectedUserId,
      })

      this.props.selectHarvest(item, isOnline, query)
      this.setState({ isOpen: false })
    })
  }

  render() {
    const { selected, harvests } = this.props

    return (
      /* eslint-disable-next-line */
      <div
        className={`dropdown ${this.state.isOpen ? 'dropdown--is-open' : ''}`}
        onClick={() => this.toggleDropdown()}
      >
        <Icon className="dropdown__icon" />
        <h3 className="dropdown__selected">{selected ? selected.name : ''}</h3>
        <div className="dropdown__separator" />
        <Chevron className="dropdown__trigger" />
        {harvests && harvests.length > 0 && selected && (
          <ul className="dropdown__content">
            {harvests.map(i => (
              /* eslint-disable-next-line */
              <li
                key={i.name || i}
                className={`dropdown__item ${
                  i.name === selected.name ? 'dropdown__item--selected' : ''
                }`}
                onClick={() => this.handleClick(i)}
              >
                {i.name || i}
              </li>
            ))}
          </ul>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  harvests: state.harvest.harvests,
  selected: state.harvest.selected,
  selectedUserId: state.auth.generalAdminIntoProfileId,
})

const mapDispatchToProps = dispatch => ({
  selectHarvest: (harvest, isOnline, query) =>
    dispatch(setSelectedHarvest(harvest, isOnline, query)),
  getNetworkStatus: () => dispatch(getNetworkStatus()),
})

export default connect(mapStateToProps, mapDispatchToProps)(HarvestSelector)
