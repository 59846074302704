import React from 'react'
import { connect } from 'react-redux'
import farmIcon from 'assets/vectors/farm.svg'
import { setSelectedFarm } from 'store/actions/farm'
import { PIVOTS_ROUTE } from 'routes/consts'
import { clearCart } from 'store/actions/cart'
import { PrimaryInput, EmptyScreen, FarmListItem, Loader } from 'components'
import { farmsListHeaders, filterFarms, formatPivotsMessage } from './helpers'
import './FarmList.scss'

class FarmList extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      searchText: '',
    }
  }

  goToPivots(farm) {
    this.props.setSelectedFarm(farm)
    this.props.router.navigate({ name: PIVOTS_ROUTE })
  }

  render() {
    const {
      farms,
      cart,
      selected,
      openAbandonCartModal,
      isLoadingNucleus,
      isLoadingHarvests,
    } = this.props
    const { searchText } = this.state
    const cartLength = Object.values(cart).length

    if (isLoadingNucleus || isLoadingHarvests) {
      return (
        <div className="page-loading">
          <Loader />
        </div>
      )
    }

    if (farms.length <= 0) {
      return (
        <EmptyScreen
          icon={farmIcon}
          title="Nenhuma fazenda encontrada"
          description="Não existe nenhuma fazenda criada até o momento."
        />
      )
    }

    return (
      <>
        <div className="farm-list__searchbar">
          <PrimaryInput
            placeholder="Busque pelo nome da fazenda"
            onChange={e => this.setState({ searchText: e.target.value })}
            value={searchText}
            hideLabel
          />
        </div>
        <div className="farm-list__list-header--only-desktop">
          {farmsListHeaders.map(header => (
            <p key={header.id}>{header.name}</p>
          ))}
        </div>
        <ul className="farm-list__container">
          {farms
            .filter(farm => filterFarms(farm, searchText))
            .map(farm => {
              const pivotsMessage = formatPivotsMessage(farm.pivots.length)
              return (
                <li
                  key={`${farm.id}`}
                  className="farm-list__item"
                  onClick={() => {
                    if (cartLength > 0 && farm.id !== selected.id) {
                      openAbandonCartModal(farm)
                    } else {
                      this.goToPivots(farm)
                    }
                  }}
                >
                  <FarmListItem name={farm.name} activePivotsMessage={pivotsMessage} />
                </li>
              )
            })}
        </ul>
      </>
    )
  }
}

const mapStateToProps = state => ({
  farms: state.farm.farms,
  selected: state.farm.selected,
  cart: state.cart.cart,
  isLoadingNucleus: state.nucleus.isLoadingNucleus,
  isLoadingHarvests: state.harvest.isLoadingHarvests,
})

const mapDispatchToProps = dispatch => ({
  setSelectedFarm: farm => dispatch(setSelectedFarm(farm)),
  clearCart: () => dispatch(clearCart()),
})

export default connect(mapStateToProps, mapDispatchToProps)(FarmList)
