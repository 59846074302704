import React from 'react'
import { ReactComponent as CartBag } from 'assets/vectors/cart-bag.svg'
import { useDispatch, useSelector } from 'react-redux'
import { toggleCartOpen } from 'store/actions/cart'

import './CartResume.scss'
import keyCodes from '../../utils/keyCodes'

const CartResume = () => {
  const { cart } = useSelector(state => state.cart)
  const dispatch = useDispatch()
  const products = Object.values(cart)
  const cartLength = products.length

  function handleKeyDown(e) {
    if ([keyCodes.ENTER, keyCodes.SPACE].includes(e.keyCode)) {
      dispatch(toggleCartOpen())
    }
  }

  return (
    <div
      className="cart-resume"
      role="button"
      tabIndex={0}
      onClick={() => dispatch(toggleCartOpen())}
      onKeyDown={handleKeyDown}
    >
      <CartBag />
      <p className="cart-resume__number">{cartLength}</p>
      <p className="cart-resume__text">Produtos</p>
    </div>
  )
}

export default CartResume
