import React from 'react'
import Helmet from 'react-helmet'
import { Page } from 'framework7-react'
import { connect } from 'react-redux'
import { setSelectedProduct, setSelectedPivot, addToCart } from 'store/actions/cart'
import { isDesktopDevice } from 'utils/layout'
import { CartLayout, Form, DefaultHeader, Cart, PrimaryButton, Slider } from 'components'
import './ConfigProduct.scss'
import ConfigProductItemTitle from './ConfigProductItemTitle/ConfigProductItemTitle'
import Dosage from './Dosage/Dosage'
import Quantity from './Quantity/Quantity'
import ApplicationForm from './ApplicationForm/ApplicationForm'
import CenterPivot from './CenterPivot/CenterPivot'

class ConfigProduct extends React.Component {
  constructor(props) {
    super(props)

    const dosage = props.selectedPivot
      ? props.selectedPivot.dosage || props.selectedProduct.minDosage
      : 0

    const fixedDosage = Number(dosage)?.toFixed(3)
    this.state = {
      ...props.selectedPivot,
      applicationType: (props.selectedPivot && props.selectedPivot.applicationType) || null,
      area: props.selectedPivot ? props.selectedPivot.area : 0,
      dosage: fixedDosage,
      inputDosage: fixedDosage,
      isDesktop: isDesktopDevice(),
    }

    this.state.quantity =
      (props.selectedPivot && props.selectedPivot.quantity) ||
      this.updateQuantity(this.state).quantity

    this.isEdit = !!this.state.applicationType
  }

  handleSubmit() {
    const { selectedProduct, selectedFarm, chemicalResale } = this.props
    const sendProduct = { ...selectedProduct, unitMeasure: chemicalResale.unitMeasure }

    if (selectedProduct.stocks && selectedProduct.stocks.length > 0)
      sendProduct.stock = selectedProduct.stocks.find(
        h => h.farmId === selectedFarm.id && h.actual > 0,
      )

    this.props.addToCart(sendProduct, this.state)
    this.$f7router.back()
  }

  updateQuantity({ dosage = this.state.dosage, area = this.state.area }) {
    const quantity = +(dosage * area).toFixed(3)

    return { dosage, area, quantity, inputDosage: dosage }
  }

  verifyDosage(dosage) {
    const { selectedProduct } = this.props
    const { minDosage, maxDosage } = selectedProduct
    let finalDosage = Number(dosage).toFixed(3)

    if (finalDosage < selectedProduct.minDosage) finalDosage = minDosage.toFixed(3)

    if (selectedProduct.maxDosage && finalDosage > selectedProduct.maxDosage)
      finalDosage = maxDosage.toFixed(3)

    this.setState(this.updateQuantity({ dosage: finalDosage }))
  }

  isValidForm() {
    const { applicationType, area } = this.state
    return !!applicationType && area !== ''
  }

  render() {
    const { selectedProduct, selectedPivot, chemicalResale } = this.props
    const pageTitle = 'Corteva Workflow: Configurar Produto'

    let areaWidth = 0

    for (let index = 0; index <= 6; index += 1) {
      if (this.state.area >= 10 ** index) areaWidth = index
    }

    return (
      <Page className="config-product-page">
        <Helmet title={pageTitle} />
        <CartLayout withCart>
          <DefaultHeader
            router={this.$f7router}
            headerTitle={
              <div className="config-product-page__header">
                <h1 className="config-product-page__header__title">{selectedProduct.name}</h1>
                <p className="config-product-page__header__days">{`Previsão de entrega: ${chemicalResale.resale.weekDaysToDeliver}`}</p>
              </div>
            }
            beforeTitle={selectedPivot.name}
            isModal={this.state.isDesktop}
          />
          <div className="config-product-page__body">
            <Form>
              <div className="config-product-page__item">
                <div className="config-product-page__item__header">
                  {selectedProduct.maxDosage ? (
                    <Dosage
                      minDosage={Number(selectedProduct.minDosage.toFixed(3))}
                      maxDosage={Number(selectedProduct.maxDosage.toFixed(3))}
                      inputDosage={this.state.inputDosage}
                      handleInputChange={newValue => this.setState({ inputDosage: newValue })}
                      handleInputBlur={value => this.verifyDosage(value)}
                      unitMeasure={chemicalResale.unitMeasure}
                    />
                  ) : (
                    <ConfigProductItemTitle
                      name="Dosagem"
                      quantityTitle={`${this.state.dosage} ${chemicalResale.unitMeasure}`}
                    />
                  )}
                </div>
                {selectedProduct.maxDosage && (
                  <Slider
                    value={this.state.dosage}
                    min={Number(selectedProduct.minDosage.toFixed(3))}
                    max={Number(selectedProduct.maxDosage.toFixed(3))}
                    step={0.001}
                    onChange={newValue => this.setState(this.updateQuantity({ dosage: newValue }))}
                  />
                )}
              </div>
              <div className="config-product-page__item">
                <Quantity
                  stocks={chemicalResale.stocks}
                  unitMeasure={chemicalResale.unitMeasure}
                  quantity={this.state.quantity}
                />
              </div>
              <div className="config-product-page__item">
                <ApplicationForm
                  handleSelectChange={applicationType =>
                    this.setState({
                      ...this.updateQuantity({ area: selectedPivot.area }),
                      applicationType,
                    })
                  }
                  applicationType={this.state.applicationType}
                />
              </div>
              {this.state.applicationType && this.state.applicationType.value === 2 && (
                <div className="config-product-page__item">
                  <CenterPivot
                    area={this.state.area}
                    areaWidth={areaWidth}
                    handleInputChange={value => this.setState(this.updateQuantity({ area: value }))}
                  />
                </div>
              )}
              <PrimaryButton disabled={!this.isValidForm()} onClick={() => this.handleSubmit()}>
                {this.isEdit ? 'Salvar o produto' : 'Adicionar ao carrinho'}
              </PrimaryButton>
            </Form>
          </div>
        </CartLayout>
        <div slot="fixed">
          <Cart />
        </div>
      </Page>
    )
  }
}

const mapStateToProps = state => {
  const selectedProduct = state.cart.selected || state.product.selected
  return {
    selectedPivot: state.cart.selectedPivot || state.pivot.selected,
    selectedProduct,
    selectedFarm: state.farm.selected,
    chemicalResale: state.product.chemicalResales[selectedProduct.id],
  }
}

const mapDispatchToProps = dispatch => ({
  addToCart: (product, pivot) => dispatch(addToCart(product, pivot)),
  setSelectedProduct: p => dispatch(setSelectedProduct(p)),
  setSelectedPivot: p => dispatch(setSelectedPivot(p)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ConfigProduct)
