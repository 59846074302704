import React from 'react'
import PropTypes from 'prop-types'
import { Select } from 'components'
import { APPLICATION_TYPES } from 'config/consts'

const ApplicationForm = ({applicationType, handleSelectChange}) => (
  <>
    <h1 className="config-product-page__item__name">Forma de aplicação</h1>
    <p className="config-product-page__item__description">
      Selecione a forma de aplicação que será realizada para aplicação do químico no
      pivô.
    </p>
    <Select
      placeholder="Selecione"
      options={APPLICATION_TYPES}
      value={applicationType}
      onChange={newApplicationType => handleSelectChange(newApplicationType)}
    />
  </>
)

ApplicationForm.propTypes = {
  applicationType: PropTypes.string.isRequired, 
  handleSelectChange: PropTypes.func.isRequired
}
export default ApplicationForm