import {
  SET_SELECTED_PRODUCT,
  SET_PRODUCTS,
  UPDATE_STOCK,
  SET_ALL_PRODUCTS,
} from '../actions/product'

const initialState = {
  selected: null,
  allProducts: [],
  products: [],
  chemicalResales: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_PRODUCT:
      return { ...state, selected: action.product }

    case SET_PRODUCTS:
      return { ...state, products: action.products, chemicalResales: action.chemicalResales }

    case UPDATE_STOCK: {
      const { chemicalResales } = state
      chemicalResales[action.id].stocks = action.stocks
      return { ...state, chemicalResales }
    }

    case SET_ALL_PRODUCTS:
      return { ...state, allProducts: action.allProducts }

    default:
      return state
  }
}
