import React from 'react'
import PropTypes from 'prop-types'

import './ConfigProductItemTitle.scss'

const ConfigProductItemTitle = ({name, description, quantityTitle}) => (
  <div className="config-product-item-title">
    <h1 className="config-product-item-title--name">{name}</h1>
    <p className={`${quantityTitle ? 'config-product-item-title--quantity': 'config-product-item-title--description'}`}>{description}</p>
  </div>
)

ConfigProductItemTitle.propTypes = {
  name: PropTypes.string.isRequired, 
  description: PropTypes.string, 
  quantityTitle: PropTypes.string
}

ConfigProductItemTitle.defaultProps = {
  description: '', 
  quantityTitle: ''
}


export default ConfigProductItemTitle