export const sortInAlphabeticalOrder = (data) => data.sort((a, b) => {
    if(a.name < b.name) return -1
    if(a.name > b.name) return 1
    return 0
  })

export const sortInDateOrder = (data) => data.sort((a, b) => new Date(b.date) - new Date(a.date))


export const filterByTerm = (data, searchTerm, type) => {
  if(searchTerm === '') return data
  if(type === 'ORDER_PAGE') return data.filter(item => item.id.toString().includes(searchTerm))
  return data.filter(item => item.name.toLowerCase().includes(searchTerm.toLowerCase()))
}

