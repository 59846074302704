import React from 'react'

import keyCodes from '../../utils/keyCodes'
import './CardList.scss'

export default ({ cards, onSelect, description }) => {
  const handleOnKeyDown = e => p => {
    if ([keyCodes.ENTER, keyCodes.SPACE].includes(e.keyCode) || onSelect) {
      onSelect(p)
    }
  }

  return (
    <ul className="card-list__container">
      {cards?.length > 0 &&
        cards.map(p => (
          <li key={p.name} className="card-list__item">
            <div
              className="item__text-container"
              role="button"
              tabIndex={0}
              onClick={() => onSelect && onSelect(p)}
              onKeyDown={handleOnKeyDown(p)}
            >
              <h1 className="card-list__item__name">{p.name}</h1>
              <p className="card-list__item__info">{description && description(p)}</p>
            </div>
          </li>
        ))}
    </ul>
  )
}
