function insertOrRemoveSelectedUserIdIntoQuery(query) {
  if (query.selectedUserId <= 0) {
    delete query.selectedUserId
    return query
  }

  return query
}

export default insertOrRemoveSelectedUserIdIntoQuery
