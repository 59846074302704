import React from 'react'
import PropTypes from 'prop-types'
import ConfigProductItemTitle from '../ConfigProductItemTitle/ConfigProductItemTitle'

const Quantity = ({stocks, unitMeasure, quantity}) => (
  <div className="config-product-page__item__header">
    <ConfigProductItemTitle 
      name='Quantidade' 
      description={`${stocks} ${unitMeasure} disponível`}/>
    <h1 className="config-product-page__item__quantity">{`${quantity} ${unitMeasure}`}</h1>
  </div>
)

Quantity.propTypes = {
  stocks: PropTypes.number.isRequired, 
  quantity: PropTypes.number.isRequired, 
  unitMeasure: PropTypes.string.isRequired, 
}
export default Quantity