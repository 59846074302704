import isEmpty from 'validator/lib/isEmpty'
import isEmail from 'validator/lib/isEmail'

export function isValidPassword(password) {
  return !isEmpty(password) && password.length >= 6
}

export function isValidEmail(email) {
  return !isEmpty(email) && isEmail(email)
}

export const isAnyFilterSelected = (orderFilters) => Object.keys(orderFilters).some(filter => orderFilters[filter] === true)