import React from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { Page } from 'framework7-react'
import { HOME_ROUTE } from 'routes/consts'
import { changePassword } from 'store/actions/auth'
import { isValidPassword } from 'utils/validations'
import {
  Content,
  PrimaryInput,
  PrimaryButton,
  Navbar,
  Form,
  Header,
  Footer,
} from 'components'
import './ChangePassword.scss'

const mustBeEqualPassword = 'As senhas devem ser iguais.'
const invalidPassword = 'A senha deve conter mais de 6 caracteres.'

class ChangePassword extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      newPassword: '',
      confirmChangePassword: '',
      error: null,
    }
  }

  handleChangePasswordChange(value) {
    let error = null
    if (!isValidPassword(value)) {
      error = invalidPassword
    }

    if (value === this.state.confirmChangePassword && isValidPassword(value)) {
      error = mustBeEqualPassword
    }

    this.setState({ newPassword: value, error })
  }

  handleConfirmChangePassword(value) {
    const { newPassword } = this.state
    const isPasswordMatch = value === newPassword

    this.setState({
      confirmChangePassword: value,
      error: !isPasswordMatch ? mustBeEqualPassword : null,
    })
  }

  handleSubmit() {
    if (!this.state.error) {
      this.props.changePassword(this.state.newPassword).then(() => {
        this.$f7router.navigate({ name: HOME_ROUTE })
      })
    }
  }

  render() {
    const pageTitle = 'Corteva Workflow: nova senha'
    const { error, newPassword, confirmChangePassword } = this.state

    return (
      <Page className="welcome-layout change-password-page">
        <Helmet title={pageTitle} />
        <Navbar router={this.$f7router} backButton center={<div>Trocar senha</div>} />
        <Content className="change-password-page__content">
          <Header
            title="Digite sua nova senha"
            content="Precisamos que você crie uma nova senha para ter acesso."
          />
          <Form>
            <PrimaryInput
              placeholder="Nova senha"
              value={newPassword}
              onChange={e => this.handleChangePasswordChange(e.target.value)}
              type="password"
              isPassword
            />
            <PrimaryInput
              placeholder="Confirmação da nova senha"
              value={confirmChangePassword}
              className={error ? 'input-primary--has-error' : ''}
              onChange={e => this.handleConfirmChangePassword(e.target.value)}
              type="password"
              isPassword
            />
            {error && (
              <div className="change-password-page__content__error">
                <p>{error}</p>
              </div>
            )}
          </Form>
        </Content>
        <Footer>
          <PrimaryButton onClick={() => this.handleSubmit()} disabled={error}>
            Redefinir senha
          </PrimaryButton>
        </Footer>
      </Page>
    )
  }
}

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => ({
  changePassword: data => dispatch(changePassword(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword)
