import React from 'react'
import { Button, Preloader } from 'framework7-react'

import './SecondaryButton.scss'

export default ({ className, children, blue, color, disabled, isLoading, ...props }) => (
  <Button
    {...props}
    className={`button-secondary ${className || ''} ${blue ? 'button-secondary--blue' : ''}`}
    color={color || 'secondary'}
    disabled={disabled || isLoading}
  >
    {!isLoading ? children : <Preloader color="white" />}
  </Button>
)
