import React from 'react'
import PropTypes from 'prop-types'
import arrow from 'assets/vectors/arrow.svg'
import './FarmListItem.scss'

const FarmListItem = ({name, activePivotsMessage}) => (
  <>
    <div className="farm-list-item__text-container">
      <h1 className="farm-list-item__text-container--name">{name}</h1>
      <p className="farm-list-item__text-container--pivots">{activePivotsMessage}</p>
    </div>
    <img className="farm-list-item__arrow" src={arrow} alt="Seta" />
  </>
)

FarmListItem.propTypes = {
  name: PropTypes.string.isRequired,
  activePivotsMessage: PropTypes.string.isRequired,
}


    
export default FarmListItem