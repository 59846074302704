import { filterByTerm, sortInAlphabeticalOrder } from 'utils/sortFunctions'
import { checkChemicalApplicationDate, checkChemicalCanceled } from '../helpers'

export const orderTableHeader =[{ id: 0, name:'Nome das fazendas' }]

export const filterFarms = (allFarmOrders, searchText) => {
  const farmKeys = Object.keys(allFarmOrders)
  const newFarmOrders = farmKeys.map((farmKey, index) => 
    ({ 
      name: farmKey, 
      id: index, 
      isAnyChemicalCanceled: checkChemicalCanceled(allFarmOrders[farmKey]), 
      isMissingApplicationDate: checkChemicalApplicationDate(allFarmOrders[farmKey])
    })
  )

  const farmsWithoutApplicationDate = newFarmOrders.filter(newOrder => newOrder.isMissingApplicationDate)
  const farmsWithApplicationDate = newFarmOrders.filter(newOrder => !newOrder.isMissingApplicationDate)
  
  const farmOrders = [...sortInAlphabeticalOrder(farmsWithoutApplicationDate), ...sortInAlphabeticalOrder(farmsWithApplicationDate)]

  if(searchText === '') return farmOrders

  return filterByTerm(farmOrders, searchText)
}

const getNewPivotOrders = (onlinePivots, offlinePivots) => onlinePivots.reduce((accumulator, pivot) => {
    const offlineOrderInPivot = offlinePivots.find(offlinePivot => offlinePivot.id === pivot.id)
    if(offlineOrderInPivot) {
      const newPivot = {...pivot, orders: pivot.orders.concat(offlineOrderInPivot.orders)}
      accumulator.push(newPivot)
    }
    else accumulator.push(pivot)
    return accumulator
  }, [])

export const getOrders = (orders, offlineOrders) => {
  const finalOrders = Object.keys(orders).reduce((accumulator, orderKey) => {
    if(offlineOrders[orderKey]){
      const newPivotOrders = getNewPivotOrders(orders[orderKey], offlineOrders[orderKey])
      accumulator[orderKey] = newPivotOrders
    }
    else accumulator[orderKey] = orders[orderKey]
    
    return accumulator
  }, {})

  return finalOrders
}