import React from 'react'
import { connect } from 'react-redux'
import PrimaryButton from '../PrimaryButton/PrimaryButton'
import SecondaryButton from '../SecondaryButton/SecondaryButton'
import Modal from '../Modal/Modal'

const CartModal = ({ cartLength, open, onContinue, onAbandon, selected, onCloseModal }) => (
  <Modal open={open} onCloseModal={() => onCloseModal()}>
    <h1 className="modal__title">Alerta de abandono de pedido</h1>
    <p className="modal__description">
      Você possui
      <span className="modal__description__name">{` ${cartLength} produtos `}</span>
      em seu carrinho para a
      <span className="modal__description__name">{` ${selected && selected.name}`}</span>
    </p>
    <PrimaryButton onClick={onContinue}>Continuar pedido</PrimaryButton>
    <SecondaryButton onClick={onAbandon}>Abandonar pedido</SecondaryButton>
  </Modal>
)

const mapStateToProps = state => ({
  selected: state.farm.selected,
})

export default connect(mapStateToProps)(CartModal)
