import React from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import ContainerHeader from '../ContainerHeader/ContainerHeader'

const HeaderSelector = ({ data, dataSelected, functionToDispatch }) => {
  const dispatch = useDispatch()
  const canShowItems = data && data.length > 0 && dataSelected
  return (
    canShowItems ? (
      <ContainerHeader
        items={data.map(n => ({
          key: n.name,
          selected: dataSelected.name === n.name,
          onClick() {
            dispatch(functionToDispatch(n))
            setTimeout(() => {
              document
                .querySelector('.container-header__item--selected')
                .scrollIntoView({ inline: 'center', behavior: 'smooth' })
            }, 50)
          },
          children: n.name,
        }))}
      />
    ) : null
  )
}

HeaderSelector.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  dataSelected: PropTypes.objectOf(PropTypes.any).isRequired,
  functionToDispatch: PropTypes.func.isRequired
}

export default HeaderSelector
