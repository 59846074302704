import React from 'react'
import PrimaryButton from '../PrimaryButton/PrimaryButton'
import SecondaryButton from '../SecondaryButton/SecondaryButton'
import RenderItem from './RenderItem'
import Modal from '../Modal/Modal'
import './FilterModal.scss'

const FilterModal = ({ open, close, nameFilters, filterOptions, handleAplyFilters }) => (
  <Modal open={open} hideIcon onCloseModal={() => close()}>
    <h1 className="modal__title">Filtros</h1>
    <div className="filter-modal__type">
      <p>{nameFilters}</p>
      <ul className="filter-modal__container">
        {filterOptions.map(option => {
          const { text, value, onChangeValue } = option
          return (
            <RenderItem
              key={text}
              text={text}
              value={value}
              checkboxOnChange={() => onChangeValue(!value)} 
            />
          )
        })}
      </ul>
    </div>
    <PrimaryButton
      onClick={handleAplyFilters}
    >
      Aplicar Filtro
    </PrimaryButton>
    <SecondaryButton onClick={close}>
      Cancelar
    </SecondaryButton>
  </Modal>
)

export default FilterModal