import React from 'react'
import Helmet from 'react-helmet'
import { Page } from 'framework7-react'
import { connect } from 'react-redux'
import {
  Content,
  Header,
  PrimaryButton,
  Form,
  Footer,
  LogoWithIcon,
  Navbar,
} from 'components'
import { login } from 'store/actions/auth'


import './UnsupportedRole.scss'


class UnsupportedRole extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {}

  goBack() {
    this.$f7router.back()
  }

  render() {
    const pageTitle = 'Corteva Workflow: Entrar'
    return (
      <Page className="unsupported-role-page">
        <Helmet title={pageTitle} />
        <Navbar center={<div>Acesso inválido</div>} />
        <Content>
          <Form>
            <LogoWithIcon />
            <Header
              title="Entre pelo nosso site"
              content="Você não faz parte do time de técnicos da Corteva Workflow. Por este motivo, seu acesso é feito exclusivamente pelo nosso site."
              centered
            />
          </Form>
        </Content>
        <Footer>
          <PrimaryButton
            fill
            color="primary"
            onClick={() => {
              this.goBack()
            }}
          >
            Ok
          </PrimaryButton>
        </Footer>
      </Page>
    )
  }
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  login: user => dispatch(login(user)),
})

export default connect(mapStateToProps, mapDispatchToProps)(UnsupportedRole)
