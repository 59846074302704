import React from 'react'
import PropTypes from 'prop-types'
import './FormLine.scss'

const FormLine = ({splitIntoColumns, name, description, children}) => {
  
  const contentLine = () => {
    if(splitIntoColumns){
      return (
        <div className="form-line__title">
          <p className="form-line__name">{name}</p>
          <p className="form-line__quantity">{description}</p>
        </div>
      )
    }
    return (
      <>
        <p className="form-line__name">{name}</p>
        <p className="form-line__description">{description}</p>
      </>
    )
  }

  return (
     <div className="form-line">
       {contentLine()}
       {children}
     </div>
  )
}

FormLine.propTypes = {
  splitIntoColumns: PropTypes.bool,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}

FormLine.defaultProps = {
  splitIntoColumns: false,
}

export default FormLine