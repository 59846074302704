import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import userService from '../../../../../services/user'

import { changeGeneralAdmToAnotherProfile } from '../../../../../store/actions/auth'

import PrimaryInput from '../../../../../components/PrimaryInput/PrimaryInput'
import PrimaryButton from '../../../../../components/PrimaryButton/PrimaryButton'

import './ChooseTechnician.scss'
import { PROFILE_TECHNICIAN } from '../../../../../config/consts'

const ChooseTechnician = ({ handleCloseModal }) => {
  const dispatch = useDispatch()
  const { generalAdminIntoProfileId } = useSelector(({ auth }) => auth)

  const [technicians, setTechnicians] = useState([])
  const [filteredTechnicians, setFilteredTechnicians] = useState([])
  const [search, setSearch] = useState('')
  const [selectedTechnician, setSelectedTechnician] = useState(generalAdminIntoProfileId)
  const [canProceed, setCanProceed] = useState(generalAdminIntoProfileId > 0)

  const fetchTechnicians = async () => {
    const {
      data: { items },
    } = await userService.getAllTechnicians()

    setTechnicians(items)
    setFilteredTechnicians(items)
  }

  const handleSearchTechnicians = searchValue => {
    setSearch(searchValue)
    const newFilteredTechnicians = technicians.filter(technician =>
      technician.name
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .includes(
          searchValue
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, ''),
        ),
    )
    setFilteredTechnicians(newFilteredTechnicians)
  }

  const handleChangeProfile = async () => {
    const profileChangeByGeneralAdm = true
    dispatch(
      changeGeneralAdmToAnotherProfile({
        profileId: PROFILE_TECHNICIAN,
        userId: selectedTechnician,
        profileChangeByGeneralAdm,
      }),
    )
    handleCloseModal(selectedTechnician)
  }

  useEffect(() => {
    fetchTechnicians()
  }, [])

  return (
    <>
      <header className="technician-profile-info__header">
        <h2 className="technician-profile-info__header__title">Pesquisa de técnicos</h2>
      </header>
      <div className="technician-profile-info__wrapper">
        <div className="technician-profile-info__wrapper__content">
          <h2 className="technician-profile-info__wrapper__content__title">
            Selecione o técnico que deseja visualizar:
          </h2>
          <div className="technician-profile-info__wrapper__content__searchbar">
            <PrimaryInput
              placeholder="Busque pelo nome"
              onChange={e => {
                handleSearchTechnicians(e.target.value)
              }}
              hideLabel
              value={search}
            />
          </div>
          <div className="technician-profile-info__wrapper__content__container">
            {filteredTechnicians.map(technician => (
              <label
                key={technician.id}
                htmlFor={technician.id}
                className="technician-profile-info__wrapper__content__container__text"
              >
                <input
                  id={technician.id}
                  type="radio"
                  name="resale"
                  value={technician.id}
                  checked={selectedTechnician === technician.id}
                  onChange={() => {
                    setSelectedTechnician(technician.id)
                    setCanProceed(true)
                  }}
                  className="technician-profile-info__wrapper__content__container__text__input"
                />
                {technician.name}
              </label>
            ))}
          </div>
          <div className="technician-profile-info__wrapper__footer">
            <PrimaryButton onClick={handleChangeProfile} disabled={!canProceed}>
              Avançar
            </PrimaryButton>
          </div>
        </div>
      </div>
    </>
  )
}

export default ChooseTechnician
