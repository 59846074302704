import React from 'react'
import { Button, Preloader } from 'framework7-react'

import './PrimaryButton.scss'

export default ({ className, white, color, disabled, isLoading, children, ...props }) => (
  <Button
    {...props}
    className={`button-primary ${className || ''} ${white ? 'button-primary--white' : ''}`}
    fill
    color={color || 'primary'}
    disabled={disabled || isLoading}
  >
    {!isLoading ? children : <Preloader color="white" />}
  </Button>
)
