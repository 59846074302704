import axiosPrivateInstance from 'middlewares/AxiosInstance'
import queryString from 'query-string'
import insertOrRemoveSelectedUserIdIntoQuery from '../utils/insertOrRemoveSelectedUserIdIntoQuery'

const getForTechnician = (harvestId, query = {}) => {
  const newQuery = insertOrRemoveSelectedUserIdIntoQuery({ harvestId, ...query })
  const queryUrl = queryString.stringify(newQuery)

  return axiosPrivateInstance.get(
    `/farms-for-technician?${queryUrl}`)
}

export default { getForTechnician }
