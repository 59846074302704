import React from 'react'
import Helmet from 'react-helmet'
import { Page } from 'framework7-react'
import { useSelector, useDispatch } from 'react-redux'
import { setModalFilter, setOrderFilters } from 'store/actions/ui'
import { setSelectedProduct } from 'store/actions/order'
import {
  Layout,
  Navbar,
  OrderProductList,
  LayoutOrderPage,
  FilterModal,
  EmptyScreen,
} from 'components'
import { createPageTitle } from 'utils/createPageTitle'
import { ORDER_APPLICATION_ROUTE } from 'routes/consts'
import storageIcon from 'assets/vectors/storage.svg'
import { createBreadcrumbItems, getProducts, filterProducts } from './helpers'
import './OrderProducts.scss'

const OrderProducts = ({ f7router }) => {
  const dispatch = useDispatch()
  const [searchText, setSearchText] = React.useState('')
  const { farmName } = useSelector(({ order }) => order.selectedFarm)
  const { name: pivotName } = useSelector(({ order }) => order.selectedPivot)
  const { selected, applicationDates } = useSelector(({ order }) => order)
  const { name: nucleusName } = useSelector(({ nucleus }) => nucleus.selected)
  const { products } = useSelector(({ product }) => product)
  const orderFilters = useSelector(({ ui }) => ui.orderFilters)
  const { chemicals, date } = selected

  const isFilterModalOpen = useSelector(({ ui }) => ui.isFilterModalOpen)

  const [orderReceived, setOrderReceived] = React.useState(false)
  const [orderCanceled, setOrderCanceled] = React.useState(false)
  const [orderBilled, setOrderBilled] = React.useState(false)
  const [orderStock, setOrderStock] = React.useState(false)
  const [orderAccepted, setOrderAccepted] = React.useState(false)

  const productsData = getProducts(chemicals, products, selected, applicationDates, date)
  const productsDataFiltered = filterProducts(productsData, searchText, orderFilters)

  const filterOptions = [
    {
      text: 'Enviados',
      value: orderReceived,
      onChangeValue: setOrderReceived,
    },
    {
      text: 'Cancelados',
      value: orderCanceled,
      onChangeValue: setOrderCanceled,
    },
    {
      text: 'Faturados',
      value: orderBilled,
      onChangeValue: setOrderBilled,
    },
    {
      text: 'Usados do Estoque',
      value: orderStock,
      onChangeValue: setOrderStock,
    },
    {
      text: 'Aceitos',
      value: orderAccepted,
      onChangeValue: setOrderAccepted,
    },
  ]

  const handleFilterButton = () => {
    dispatch(setModalFilter(true))
  }

  const handleAplyFilters = () => {
    dispatch(
      setOrderFilters({
        orderReceived,
        orderCanceled,
        orderBilled,
        orderStock,
        orderAccepted,
      }),
    )
    dispatch(setModalFilter(false))
  }

  const handleClose = () => {
    setOrderReceived(false)
    setOrderCanceled(false)
    setOrderBilled(false)
    setOrderStock(false)
    setOrderAccepted(false)
    dispatch(
      setOrderFilters({
        orderReceived: false,
        orderCanceled: false,
        orderBilled: false,
        orderStock: false,
        orderAccepted: false,
      }),
    )
    dispatch(setModalFilter(false))
  }

  const handleSelectChemical = chemicalObject => {
    dispatch(setSelectedProduct(chemicalObject))
    f7router.navigate({ name: ORDER_APPLICATION_ROUTE })
  }

  return (
    <Page className="order-products-page">
      <Helmet title={createPageTitle('Produtos')} />
      <Layout
        breadcrumbItems={createBreadcrumbItems(nucleusName, farmName, pivotName, selected.id)}
      >
        <Navbar router={f7router} backButton center={selected.id} />
        <div className="order-products-page__body">
          <LayoutOrderPage
            placeholder="Busque pelo nome do químico"
            searchText={searchText}
            setSearchText={setSearchText}
            isFilterButton
            filterModalOnClick={() => handleFilterButton()}
          >
            {productsDataFiltered.length > 0 ? (
              <OrderProductList
                router={f7router}
                chemicals={productsDataFiltered}
                chemicalLineClick={handleSelectChemical}
              />
            ) : (
              <EmptyScreen icon={storageIcon} title="Nenhum químico encontrado" description="" />
            )}
          </LayoutOrderPage>
        </div>
      </Layout>
      <FilterModal
        open={isFilterModalOpen}
        close={handleClose}
        nameFilters="Data de Aplicação"
        filterOptions={filterOptions}
        handleAplyFilters={handleAplyFilters}
      />
    </Page>
  )
}

export default OrderProducts
