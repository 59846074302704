import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ReactComponent as ArrowDown } from 'assets/vectors/chevron-down.svg'
import localStorage from 'services/localstorage'
import { logout } from 'store/actions/auth'
import reducers from 'store'

import { setModalOpenOrClose } from 'store/actions/ui'

import './UserProfile.scss'
import { PROFILE_GENERAL_ADM, MODAL_NAME } from '../../../config/consts'

const UserProfile = () => {
  const [isDropdownHidden, setIsDropdownHidden] = React.useState(true)
  const { profile, profileChangeByGeneralAdm } = useSelector(state => state.auth)
  const dispatch = useDispatch()

  async function handleLogoff() {
    await reducers.persistor.pause()
    localStorage.clearState()
    dispatch(logout())
    window.location.replace('/login')
  }

  function handleChangeProfile() {
    dispatch(
      setModalOpenOrClose({
        modalName: MODAL_NAME.changeProfileByChangeManager,
        isOpenModal: true,
      }),
    )
    setIsDropdownHidden(!isDropdownHidden)
  }

  return (
    <div className="user-profile">
      <button
        className="user-profile__button"
        aria-haspopup="true"
        aria-expanded={isDropdownHidden}
        onClick={() => setIsDropdownHidden(prevState => !prevState)}
      >
        <ArrowDown
          className={`user-profile__arrow ${
            !isDropdownHidden ? 'user-profile__arrow--inverted' : ''
          }`}
        />
        <div className="user-profile__avatar">{profile.initials}</div>
        <div className="user-profile__name">{profile.name}</div>
      </button>
      {!isDropdownHidden && (
        <div className="user-profile__container-button">
          {(profileChangeByGeneralAdm || profile.id === PROFILE_GENERAL_ADM) && (
            <button
              className={`user-profile__container-button__logoff-menu ${
                isDropdownHidden ? 'user-profile__container-button__logoff-menu--hidden' : ''
              }`}
              aria-hidden={isDropdownHidden}
              onClick={handleChangeProfile}
            >
              Trocar de perfil
            </button>
          )}
          <button
            className={`user-profile__container-button__logoff-menu ${
              isDropdownHidden ? 'user-profile__container-button__logoff-menu--hidden' : ''
            }`}
            aria-hidden={isDropdownHidden}
            onClick={handleLogoff}
          >
            Sair
          </button>
        </div>
      )}
    </div>
  )
}

export default UserProfile
