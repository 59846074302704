import queryString from 'query-string'
import axiosPrivateInstance from 'middlewares/AxiosInstance'
import insertOrRemoveSelectedUserIdIntoQuery from '../utils/insertOrRemoveSelectedUserIdIntoQuery'

const getOrders = (harvestId, query = {}) => {
  const newQuery = insertOrRemoveSelectedUserIdIntoQuery({ harvestId, ...query })
  const queryUrl = queryString.stringify(newQuery)

  return axiosPrivateInstance.get(`/orders?${queryUrl}`)
}

const saveOrders = data => axiosPrivateInstance.post(`/orders`, { data })

const saveApplicationDates = data =>
  axiosPrivateInstance.post('/orders/application-dates', { data })

const getPivotsForOfflineOrder = o => {
  const pivots = {}

  Object.keys(o.chemicals).forEach(c => {
    const chemical = o.chemicals[c]
    Object.keys(chemical.pivots).forEach(p => {
      const pivot = { ...chemical.pivots[p] }
      const newChemical = { ...pivot, ...chemical }

      delete newChemical.pivots
      delete newChemical.area
      delete newChemical.name

      delete pivot.applicationType
      delete pivot.dosage

      if (pivots[p]) pivots[p].chemicals.push(newChemical)
      else pivots[p] = { ...pivot, chemicals: [newChemical] }
    })
  })

  delete o.chemicals

  return pivots
}

const getPivotsForOrder = o => {
  const pivots = {}
  o.orderPivotChemicals.forEach(chemical => {
    const pivot = { ...chemical.pivot }
    if (pivots[pivot.id]) pivots[pivot.id].chemicals.push(chemical)
    else pivots[pivot.id] = { ...pivot, chemicals: [chemical] }
  })

  delete o.orderPivotChemicals

  return pivots
}

const getPivotIndex = (accumulator, pivots, pivotKey) =>
  accumulator.findIndex(acc => acc.id === pivots[pivotKey].id)

const getOrderFromFarms = (farms, orders) =>
  orders.reduce((accumulator, order) => {
    const newOrderObject = { ...order }
    const farm = farms.find(f => f.id === newOrderObject.farmId)
    if (farm) {
      const farmName = farm.name
      const isOfflineOrder = !!newOrderObject.chemicals
      const pivots = isOfflineOrder
        ? getPivotsForOfflineOrder(newOrderObject)
        : getPivotsForOrder(newOrderObject)
      const pivotsKey = Object.keys(pivots)
      const { id, date, resaleOrders, user } = newOrderObject

      pivotsKey.forEach(pivotKey => {
        const newOrder = {
          id,
          chemicals: pivots[pivotKey].chemicals,
          date,
          resaleOrders,
          technician: user?.name,
        }

        const newPivot = {
          id: pivots[pivotKey].id,
          name: pivots[pivotKey].name,
          orders: [newOrder],
        }

        if (accumulator[farmName]) {
          const pivotIndex = getPivotIndex(accumulator[farmName], pivots, pivotKey)
          if (pivotIndex !== -1) accumulator[farmName][pivotIndex].orders.push(newOrder)
          else accumulator[farmName].push(newPivot)
        } else accumulator[farmName] = [newPivot]
      })
    }
    return accumulator
  }, {})

const filterOfflineOrders = (orders, allOfflineOrders) =>
  allOfflineOrders.filter(
    offlineOrder =>
      !orders.find(
        order =>
          !!order.guid &&
          order.guid === offlineOrder.guid &&
          order.farmId === offlineOrder.farmId &&
          order.harvestId === offlineOrder.harvestId,
      ),
  )

const requestOrderCancel = ({ chemicalOrderId, reason }) =>
  axiosPrivateInstance.post(`/resales/chemical-cancel-request/${chemicalOrderId}`, { reason })

export default {
  saveOrders,
  getOrders,
  saveApplicationDates,
  filterOfflineOrders,
  getOrderFromFarms,
  requestOrderCancel,
}
