import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ORDER_PIVOTS_ROUTE } from 'routes/consts'
import { setSelectedFarm } from 'store/actions/order'
import farmIcon from 'assets/vectors/farm.svg'
import { OrderPageType } from 'utils/ordersPageMessage'
import { OrderTable, EmptyScreen, LayoutOrderPage, Loader } from 'components'
import { orderTableHeader, filterFarms, getOrders } from './helpers'

const OrderFarms = ({ router }) => {
  const dispatch = useDispatch()
  const [searchText, setSearchText] = React.useState('')
  const isLoadingOrders = useSelector(({order}) => order.isLoading)
  const isLoadingNucleus = useSelector(({nucleus}) => nucleus.isLoadingNucleus)
  const isLoadingHarvests = useSelector(({harvest}) => harvest.isLoadingHarvests)
  const farmOrders = useSelector(({order}) => order.orders)
  const offlineFarmOrders = useSelector(({order}) => order.offlineOrders)
  const allFarmOrders = getOrders(farmOrders, offlineFarmOrders)
  const farmKeys = filterFarms(allFarmOrders, searchText)

  const handleSelectFarm = farmKey => {
    const { name: key } = farmKey
    const farmSelected = { farmName: key, pivots: allFarmOrders[key] }
    dispatch(setSelectedFarm(farmSelected))
    router.navigate({ name: ORDER_PIVOTS_ROUTE })
  }

  if (isLoadingOrders || isLoadingHarvests || isLoadingNucleus) {
    return (
      <div className="page-loading">
        <Loader />
      </div>
    )
  }
  
  return (
    <section>
      <LayoutOrderPage placeholder="Busque pelo nome da fazenda" searchText={searchText} setSearchText={setSearchText}>
        <div className='order-list'>
          {farmKeys.length > 0 ? 
            <div className="order-list__farm-orders">
              <OrderTable headers={orderTableHeader} data={farmKeys} lineClick={handleSelectFarm} orderPageType={OrderPageType.farm} />
            </div>
          :
            (
              <EmptyScreen
                icon={farmIcon}
                title="Nenhuma fazenda encontrada"
                description=""
              />
            )}
        </div>
      </LayoutOrderPage>
    </section>
  )
}

export default OrderFarms
