import { SET_SELECTED_PIVOT } from '../actions/pivot'

import { SET_SELECTED_FARM } from '../actions/farm'

const initialState = {
  selected: null,
  pivots: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_PIVOT:
      return { ...state, selected: action.pivot }

    case SET_SELECTED_FARM:
      return { ...state, pivots: (action.farm && action.farm.pivots) || [] }

    default:
      return state
  }
}
