import React from 'react'
import Helmet from 'react-helmet'
import { Page } from 'framework7-react'
import { connect } from 'react-redux'
import { FORGOT_PASSWORD_EMAIL_ROUTE } from 'routes/consts'
import { Content, PrimaryInput, PrimaryButton, Navbar, Form, Header } from 'components'
import { requestNewPassword } from 'store/actions/auth'
import './ForgotPassword.scss'

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
    }
  }

  requestPassword() {
    this.props.requestNewPassword(this.state.email).then(() => {
      this.$f7router.navigate({ name: FORGOT_PASSWORD_EMAIL_ROUTE })
    })
  }

  render() {
    const pageTitle = 'Corteva Workflow: Esqueci minha senha'
    return (
      <Page className="welcome-layout forgot-password-page">
        <Helmet title={pageTitle} />
        <Navbar router={this.$f7router} center={<div>Esqueci minha senha</div>} backButton />
        <Content className="forgot-password-page__content">
          <Header
            title="Digite seu email"
            content="Enviaremos um email com sua nova senha, mas será necessário redefini-lá ao acessar o
              aplicativo."
          />
          <Form>
            <PrimaryInput
              placeholder="E-mail"
              type="email"
              value={this.state.email}
              onChange={e => this.setState({ email: e.target.value })}
            />
          </Form>
          <PrimaryButton
            onClick={() => this.requestPassword()}
            disabled={!this.state.email || !this.state.email.length > 0}
          >
            Enviar nova senha
          </PrimaryButton>
        </Content>
      </Page>
    )
  }
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  requestNewPassword: data => dispatch(requestNewPassword(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)
