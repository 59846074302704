import React from 'react'
import Helmet from 'react-helmet'
import { Page } from 'framework7-react'
import { LOGIN_ROUTE } from 'routes/consts'
import { Content, Footer, PrimaryButton, SecondaryButton, Navbar, Header } from 'components'
import emailSentIcon from 'assets/vectors/email-sent.svg'
import './ForgotPasswordEmailSent.scss'

class ForgotPasswordEmailSent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <Page className="welcome-layout forgot-password-email-sent-page">
        <Helmet title="Corteva Workflow: Email enviado" />
        <Navbar center={<div>Esqueci minha senha</div>} />
        <Content className="forgot-password-email-sent-page__content">
          <img
            className="forgot-password-email-sent-page__illustration"
            src={emailSentIcon}
            alt="Email enviado"
          />
          <Header
            title="E-mail enviado"
            content="Em alguns instantes você receberá seu e-mail com a nova senha."
          />
        </Content>
        <Footer>
          <PrimaryButton onClick={() => this.$f7router.navigate({ name: LOGIN_ROUTE })}>
            Ok
          </PrimaryButton>
          <SecondaryButton onClick={() => this.$f7router.back()}>
            Não recebi o e-mail
          </SecondaryButton>
        </Footer>
      </Page>
    )
  }
}

export default ForgotPasswordEmailSent
