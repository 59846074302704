import React from 'react'
import { List } from 'framework7-react'

import './Form.scss'

export default ({ children }) => (
  <List className="form">
    <form>{children}</form>
  </List>
)
