import React from 'react'
import PropTypes from 'prop-types'
import { PrimaryInput } from 'components'
import ConfigProductItemTitle from '../ConfigProductItemTitle/ConfigProductItemTitle'

const Dosage = ({minDosage, maxDosage, unitMeasure, inputDosage, handleInputChange, handleInputBlur}) => (
  <>
    <ConfigProductItemTitle 
      name='Dosagem' 
      description={`Indique a dosagem desejada. (${minDosage} - ${maxDosage})`}
    />
    <div className="config-product-page__item__volume">
      <PrimaryInput
        className="config-product-page__item__dosage"
        value={inputDosage}
        onChange={e => handleInputChange(e.target.value)}
        onBlur={e => handleInputBlur(e.target.value)}
        required
        type="number"
        min={minDosage}
        max={maxDosage}
      />
      <h1 className="config-product-page__item__quantity">{`${unitMeasure}`}</h1>
    </div>
  </>
)

Dosage.propTypes = {
  minDosage: PropTypes.number.isRequired, 
  maxDosage: PropTypes.number.isRequired, 
  unitMeasure: PropTypes.string, 
  inputDosage: PropTypes.number.isRequired, 
  handleInputChange: PropTypes.func.isRequired, 
  handleInputBlur: PropTypes.func.isRequired
}

Dosage.defaultProps = {
  unitMeasure: '', 
}
export default Dosage