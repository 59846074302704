import productService from '../../services/product'

/* --------- Products --------- */
export const SET_PRODUCTS = 'SET_PRODUCTS'
export const SET_ALL_PRODUCTS = 'SET_ALL_PRODUCTS'

const setAllProductsSuccess = allProducts => ({
  type: SET_ALL_PRODUCTS,
  allProducts,
})

export const setAllProducts = allProducts => dispatch =>
  new Promise(resolve => resolve(dispatch(setAllProductsSuccess(allProducts))))

const setProductsSuccess = (products, chemicalResales) => ({
  type: SET_PRODUCTS,
  products,
  chemicalResales,
})

export const setProducts = nucleusId => (dispatch, getState) =>
  new Promise(resolve => {
    const { product } = getState()
    const products = productService.getProductFromNucleus(nucleusId, product.allProducts)

    const chemicalResales = products.reduce(
      (acc, p) => ({ ...acc, [p.id]: productService.getPreferenceResale(p.chemicalResales) }),
      {},
    )

    resolve(dispatch(setProductsSuccess(products, chemicalResales)))
  })

/* --------- Stock --------- */
export const UPDATE_STOCK = 'UPDATE_STOCK'

const setStock = (id, stocks) => ({
  type: UPDATE_STOCK,
  id,
  stocks,
})

export const updateStock = (id, stocks) => dispatch =>
  new Promise(resolve => resolve(dispatch(setStock(id, stocks))))

/* --------- Select --------- */
export const SET_SELECTED_PRODUCT = 'SET_SELECTED_PRODUCT'

const selectedProduct = product => ({
  type: SET_SELECTED_PRODUCT,
  product,
})

export const setSelectedProduct = product => dispatch =>
  new Promise(resolve => resolve(dispatch(selectedProduct(product))))
